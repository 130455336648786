import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AlertService } from '../alert/alert.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { addCreditNoteResult } from 'src/app/shared/models/addCreditNoteResult';
import { QueryResult } from 'src/app/shared/models/queryResult';
import { CollectivePaymentPageItem } from 'src/app/shared/models/collectivePaymentPageItem';
import { CollectivePaymentTransactionItem } from 'src/app/shared/models/collectivePaymentTransactionItem';
import { EnumDropdown } from 'src/app/shared/models/enumDropdown';

@Injectable({
    providedIn: 'root'
})
export class collectivePaymentService {

    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    constructor(private http: HttpClient, private alertService: AlertService, @Inject(LOCALE_ID) public locale: string) { 
    }

    public getAllCollectivePayments(customerId: string, keyword: string, collectionStart: string, collectionEnd: string, status: string, trigger: string, pageSize: number, pageNumber: number): Observable<QueryResult<CollectivePaymentPageItem>>
    {
      let baseEndpoint = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/customers/${customerId}/collectivePayments`;
      let queryString:[string,string][] = [
        ["keyword", keyword],
        ["collectionStart", collectionStart],
        ["collectionEnd", collectionEnd],
        ["status", status],
        ["trigger", trigger],
        ["pageSize", `${pageSize}`],
        ["pageNumber", `${pageNumber}`],
        ["culture", this.locale]
      ];

      let url = `${baseEndpoint}?${queryString.map(n=> n.join(`=`)).join(`&`)}`;

      return this.http.get<QueryResult<CollectivePaymentPageItem>>(url, { headers: this.headers })
        .pipe(map(a => {
          return a;
        }));
    }

    public getAllCollectivePaymentTransactions(customerId: string, collectivePaymentId:number): Observable<QueryResult<CollectivePaymentTransactionItem>>
    {
      let baseEndpoint = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/customers/${customerId}/collectivePayments/${collectivePaymentId}/transactions`;
      let queryString:[string,string][] = [];

      let url = `${baseEndpoint}?${queryString.map(n=> n.join(`=`)).join(`&`)}`;

      return this.http.get<QueryResult<CollectivePaymentTransactionItem>>(url, { headers: this.headers })
        .pipe(map(a => {
          return a;
        }));
    }

    public getCollectivePaymentStatus(): Observable<QueryResult<EnumDropdown>>
    {
      let baseEndpoint = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/collectivePayments/statusEnums`;

      let url = `${baseEndpoint}`;
        return this.http.get<QueryResult<EnumDropdown>>(url, { headers: this.headers })
          .pipe(map(a => {
            return a;
          }));
    }

    public getCollectivePaymentTriggerType(): Observable<QueryResult<EnumDropdown>>
    {
      let baseEndpoint = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/collectivePayments/triggerTypeEnum?culture=${this.locale}`;

      let url = `${baseEndpoint}`;
        return this.http.get<QueryResult<EnumDropdown>>(url, { headers: this.headers })
          .pipe(map(a => {
            return a;
          }));
    }
}