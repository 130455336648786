<form #frm="ngForm" (ngSubmit)="onSubmit(frm)" ngNativeValidate id="frm">
  <h1 mat-dialog-title i18n *ngIf="data.isBlocked == false">Are you sure you want to block this customer?</h1>
  <h1 mat-dialog-title i18n *ngIf="data.isBlocked == true">Are you sure you want to unblock this customer?</h1>
  <div mat-dialog-content>
    <div class="checkbox-container">
      <mat-checkbox
        *ngIf="data.isBlocked == false"
        name="finmaRegulation"
        ngModel
        i18n
        data-lokalise
        data-key="6598039858680305401"
      ></mat-checkbox>
      <span>Blocking due to FINMA-Regulation</span>
    </div>

    <mat-form-field class="form-field">
      <mat-label>Comment</mat-label>
      <textarea matInput placeholder="Your comment" name="comment" ngModel></textarea>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="dialog-actions-container">
    <button mat-raised-button color="primary" type="submit" cdkFocusInitial i18n>Confirm</button>
    <button mat-raised-button color="warn" (click)="onNoClick()" [mat-dialog-close]="true" i18n>Cancel</button>
  </div>
</form>
