<div class="card-container">
    <mat-card>
        <mat-card-header>
          <mat-card-title i18n>TCS Administration GUI</mat-card-title>
          <mat-card-subtitle i18n>Please login first</mat-card-subtitle>
        </mat-card-header>
        <mat-card-actions>
          <button mat-button (click)="login()" [disabled]="loading"><span i18n>Login</span></button>
        </mat-card-actions>
      </mat-card>
</div>