import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { CreditnotePromotionsComponent } from './components/creditnotepromotions/creditnotepromotions.component';
import { CreditnotePromotionsInfoComponent } from './components/creditnotepromotionsinfo/creditnotepromotionsinfo.component';
import { CreditnotePromotionsCustomersComponent } from './components/creditnotepromotionscustomers/creditnotepromotionscustomers.component';
import { MsalGuard } from '@azure/msal-angular';
import { CustomerlistingComponent } from './components/customerlisting/customerlisting.component';
import { ParkingTransactionHistoryListingComponent } 
  from './components/parkingtransactionhistorylisting/parkingtransactionhistorylisting.component';
import { UserRole } from './shared/models/userRole';
import { GlobalExportComponent } from './components/global-export/global-export.component';
import { NgModule } from '@angular/core';
import { CustomerInformationComponent } from './components/customerinfo/customerinformation.component';
import { environment } from 'src/environments/environment';
import { RoleGuard } from './role-guard';
import { BrowserUtils } from '@azure/msal-browser';


const routes: Routes =  environment.isLocal ?
    [
      { path: 'login', component: LoginComponent },
      {
        path: '',
        component: CustomerlistingComponent
      },
      {
        path: 'creditnotepromotions',
        component: CreditnotePromotionsComponent
      },
      {
        path: 'creditnotepromotion/:promotionId',
        component: CreditnotePromotionsInfoComponent
      },
      {
        path: 'creditnotepromotionscustomers',
        component: CreditnotePromotionsCustomersComponent
      },
      {
        path: 'customers',
        component: CustomerlistingComponent
      },
      {
        path: 'customers/:customerReferenceId',
        component: CustomerInformationComponent
      },
      {
        path: 'customers/:customerReferenceId/parkings/:parkingTransactionReferenceId',
        component: ParkingTransactionHistoryListingComponent
      },
      {
        path: 'global-export',
        component: GlobalExportComponent
      }
    ]
    :
    [
    { path: 'login', component: LoginComponent },
    { path: '',
      component: CustomerlistingComponent,
      //canActivate: [MsalGuard],  // add RoleGuard to this array to enforce roles for this route
      canActivate: [RoleGuard],  // add RoleGuard to this array to enforce roles for this route
      data:  {
        roles: [UserRole.Operator, UserRole.Administrator],
      },
    },
    {
      path: 'creditnotepromotions',
      component: CreditnotePromotionsComponent,
      canActivate: [RoleGuard],
      data:  {
        roles: [UserRole.Operator, UserRole.Administrator],
      },
    },
    {
      path: 'creditnotepromotion/:promotionId',
      component: CreditnotePromotionsInfoComponent,
      canActivate: [RoleGuard],
      data:  {
        roles: [UserRole.Operator, UserRole.Administrator],
      },
    },
    {
      path: 'creditnotepromotionscustomers',
      component: CreditnotePromotionsCustomersComponent,
      canActivate: [RoleGuard],
      data:  {
        roles: [UserRole.Operator, UserRole.Administrator],
      },
    },
    {
      path: 'customers',
      component: CustomerlistingComponent,
      canActivate: [RoleGuard],
      data:  {
        roles: [UserRole.Operator, UserRole.Administrator],
      },
    },
    { path: 'customers/:customerReferenceId',
      component: CustomerInformationComponent,
      canActivate: [RoleGuard],
      data:  { 
        roles: [UserRole.Operator, UserRole.Administrator],
      }
    },
    { path: 'customers/:customerReferenceId/parkings/:parkingTransactionReferenceId',
      component: ParkingTransactionHistoryListingComponent,
      canActivate: [RoleGuard],
      data:  { 
        roles: [UserRole.Operator, UserRole.Administrator],
      }
    },
    { 
      path: 'global-export', 
      component: GlobalExportComponent, 
      canActivate: [RoleGuard],
      data: { roles: [UserRole.Administrator]}
    }
  ];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
        // Don't perform initial navigation in iframes or popups
        initialNavigation:
            !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
                ? 'enabledNonBlocking'
                : 'disabled', // Set to enabledBlocking to use Angular Universal
    }),
],
  exports: [RouterModule],
  providers: [RoleGuard]
})
export class AppRoutingModule {
}

export class RoutePermissionChecker {
  static hasAccessInRoutePath(path, role: UserRole) {
    const route = routes.filter(m => m.path === path)[0];
    if (route) {
        const roles: Array<UserRole> = route.data['roles'];
        return roles.indexOf(role) >= 0;
    }
  }
}