<div class="customer-panel">
  <span class="cdk-visually-hidden" #tooltipClearFilter i18n>Clear All Filters</span>

  <mat-toolbar class="control-toolbar-root">
    <mat-toolbar-row>
      <span class="row-title" i18n>Customers</span>
      <div class="row-actions">
        <button mat-raised-button color="primary" (click)="clearFilters()">
          <mat-icon>clear_all</mat-icon><span i18n>Clear All Filters</span>
        </button>
        <button mat-raised-button (click)="columnSelector.show()">
          <mat-icon>view_column</mat-icon> <span i18n>Columns</span>
        </button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <app-columnselector [columns]="defaultColumns" />

  <form [formGroup]="filterForm" (ngSubmit)="fetchData(paginator.pageSize, 1)">
    <mat-toolbar class="filter-toolbar-root">
      <mat-form-field>
        <span matPrefix>
          <mat-icon><span>search</span></mat-icon>
        </span>
        <input
          matInput
          formControlName="filterKeyword"
          i18n-placeholder
          placeholder="Filter by keyword"
          type="text"
          [(ngModel)]="keywordText"
          (keyup)="keywordChanged($event)"
        />
      </mat-form-field>

      <div class="clearable">
        <mat-form-field>
          <mat-label i18n="Last Parking Date">Last Parking Date</mat-label>
          <mat-date-range-input formGroupName="lastParkingDate" [rangePicker]="lastParkingDate">
            <input matStartDate formControlName="start" />
            <input matEndDate formControlName="end" (dateChange)="handleFilterFormChange()" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="lastParkingDate" />
          <mat-date-range-picker #lastParkingDate> </mat-date-range-picker>
        </mat-form-field>
        <mat-icon
          *ngIf="filterForm.value.lastParkingDate.start && filterForm.value.lastParkingDate.end"
          (click)="clearDate('lastParkingDate')"
          class="clear-icon"
        >
          close
        </mat-icon>
      </div>

      <div class="clearable">
        <mat-form-field>
          <mat-label i18n="P&P Registration Date">P&P Registration Date</mat-label>
          <mat-date-range-input formGroupName="appRegistrationDate" [rangePicker]="appRegistrationDate">
            <input matStartDate formControlName="start" />
            <input matEndDate formControlName="end" (dateChange)="handleFilterFormChange()" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="appRegistrationDate" />
          <mat-date-range-picker #appRegistrationDate> </mat-date-range-picker>
        </mat-form-field>
        <mat-icon
          *ngIf="filterForm.value.appRegistrationDate.start && filterForm.value.appRegistrationDate.end"
          (click)="clearDate('appRegistrationDate')"
          class="clear-icon"
        >
          close
        </mat-icon>
      </div>

      <mat-form-field>
        <mat-label i18n>Payment Status</mat-label>
        <mat-select
          matNativeControl
          formControlName="lastCollectionStatus"
          [(ngModel)]="lastCollectionStatus"
          (selectionChange)="handleFilterFormChange()"
        >
          <mat-option *ngFor="let item of collectivePaymentStatusEnum" [value]="item.value">
            {{ item.text }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="clearable">
        <mat-form-field>
          <mat-label i18n="Payment Date">Payment Date</mat-label>
          <mat-date-range-input [rangePicker]="lastCollectionDate" formGroupName="lastCollectionDate">
            <input matStartDate formControlName="start" />
            <input matEndDate (dateChange)="handleFilterFormChange()" formControlName="end" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="lastCollectionDate" />
          <mat-date-range-picker #lastCollectionDate> </mat-date-range-picker>
        </mat-form-field>
        <mat-icon
          *ngIf="filterForm.value.lastCollectionDate.start && filterForm.value.lastCollectionDate.end"
          (click)="clearDate('lastCollectionDate')"
          class="clear-icon"
        >
          close
        </mat-icon>
      </div>
    </mat-toolbar>
  </form>
  <mat-divider />
</div>
<div class="pagedtable-container">
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner></mat-spinner>
  </div>
  <mat-sidenav-content>
    <div class="table-container">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        (matSortChange)="sortData($event)"
      >
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row"></td>
        </ng-container>

        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef><span i18n>position</span></th>
          <td mat-cell *matCellDef="let element">{{ element.position }}</td>
        </ng-container>

        <ng-container matColumnDef="CustomerReferenceID">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            <span i18n>Customer Reference ID</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <a routerLink="/{{ LOCALE_ID }}/customers/{{ element.customerReferenceID }}" routerLinkActive="active">{{
              element.customerReferenceID
            }}</a>
          </td>
        </ng-container>

        <ng-container matColumnDef="TCSMemberID">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear><span i18n>TCS Member ID</span></th>
          <td mat-cell *matCellDef="let element">{{ element.tcsMemberNumber }}</td>
        </ng-container>

        <ng-container matColumnDef="FirstName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear><span i18n>First Name</span></th>
          <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
        </ng-container>

        <ng-container matColumnDef="LastName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear><span i18n>Last Name</span></th>
          <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
        </ng-container>

        <ng-container matColumnDef="LastUsedCar">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear><span i18n>Last license plate</span></th>
          <td mat-cell *matCellDef="let element">{{ element.lastUsedCar }}</td>
        </ng-container>

        <ng-container matColumnDef="LastParkingDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear><span i18n>Last Parking Date</span></th>
          <td mat-cell *matCellDef="let element">{{ element.lastParkingDateText }}</td>
        </ng-container>

        <ng-container matColumnDef="EmailAddress">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear><span i18n>Email Address</span></th>
          <td mat-cell *matCellDef="let element">{{ element.emailAddress }}</td>
        </ng-container>

        <ng-container matColumnDef="ParkingAppRegistrationDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            <span i18n>P&P Registration Date</span>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.appRegistrationDateText }}</td>
        </ng-container>

        <ng-container matColumnDef="TotalUncollectedAmount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear><span i18n>Open Amount</span></th>
          <td mat-cell *matCellDef="let element">{{ element.totalUncollectedAmountText }}</td>
        </ng-container>

        <ng-container matColumnDef="LastCollectionStatus">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear><span i18n>Payment Status</span></th>
          <td mat-cell *matCellDef="let element">{{ element.lastCollectivePaymentStatusText }}</td>
        </ng-container>

        <ng-container matColumnDef="LastCollectionDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear><span i18n>Payment Date</span></th>
          <td mat-cell *matCellDef="let element">{{ element.lastCollectivePaymentDateText }}</td>
        </ng-container>

        <ng-container matColumnDef="TotalTransactionAmountForTheLast365Days">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            <span i18n data-lokalise data-key="2735570989691967555"
              >Total Transaction Amount for the Last 365 Days</span
            >
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.totalTransactionAmountForTheLast365Days | number: '1.2-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="IsBlocked">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            <span i18n data-lokalise data-key="7320879831047974890">Status</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.isBlocked == true"
              ><mat-icon style="cursor: default" title="Blocked">lock</mat-icon>
            </ng-container>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="columnSelector == undefined ? [] : columnSelector.displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: columnSelector == undefined ? [] : columnSelector.displayedColumns"
        ></tr>
      </table>
    </div>
  </mat-sidenav-content>

  <mat-paginator
    [pageSize]="defaultSettings.table.pageSize"
    [pageSizeOptions]="defaultSettings.table.pageOptions"
    (page)="handlePaginationChange()"
    showFirstLastButtons
  ></mat-paginator>
</div>
