<app-columnselector [columns]="defaultColumns"></app-columnselector>

<mat-divider></mat-divider>
<div class="pagedtable-container">
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner></mat-spinner>
  </div>

  <mat-sidenav-content>
    <table mat-table [dataSource]="dataSource" class="content-table">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row"></td>
      </ng-container>
      <!-- Position Column -->

      <ng-container matColumnDef="Date">
        <th mat-header-cell *matHeaderCellDef><span i18n>Date</span></th>
        <td mat-cell *matCellDef="let element">{{ element.createdDate | date: 'dd.MM.yyyy' }}</td>
      </ng-container>
      <ng-container matColumnDef="Time">
        <th mat-header-cell *matHeaderCellDef><span i18n data-lokalise data-key="8497528947328199741">Time</span></th>
        <td mat-cell *matCellDef="let element">{{ element.createdDate | date: 'HH:mm' }}</td>
      </ng-container>
      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef><span i18n>Status</span></th>
        <td mat-cell *matCellDef="let element">{{ element.status }}</td>
      </ng-container>
      <ng-container matColumnDef="Comment">
        <th mat-header-cell *matHeaderCellDef><span i18n>Comment</span></th>
        <td mat-cell *matCellDef="let element">{{ element.comment }}</td>
      </ng-container>
      <ng-container matColumnDef="FinmaRegulation">
        <th mat-header-cell *matHeaderCellDef>
          <span i18n data-lokalise data-key="2330060664393356612">FINMA Regulation</span>
        </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.finmaRegulation">Yes</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="TransactionAmount">
        <th mat-header-cell *matHeaderCellDef>
          <span i18n data-lokalise data-key="2735570989691967555">Total Transaction Amount</span><br /><span
            i18n
            data-lokalise
            data-key="618723974600071481"
            >for The Last 365 Days</span
          >
        </th>
        <td mat-cell *matCellDef="let element">{{ element.transactionAmount | number: '1.2-2' }}</td>
      </ng-container>
      <ng-container matColumnDef="ModifiedBy">
        <th mat-header-cell *matHeaderCellDef><span i18n>Modified By</span></th>
        <td mat-cell *matCellDef="let element">{{ element.modifiedBy }}</td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="columnSelector == undefined ? [] : columnSelector.displayedColumns; sticky: true"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columnSelector == undefined ? [] : columnSelector.displayedColumns"
      ></tr>
    </table>
  </mat-sidenav-content>
</div>
