import { DatePipe } from '@angular/common';
import { Component, Inject, AfterViewInit } from '@angular/core';
import { FormControl, Validators, FormBuilder, UntypedFormGroup, ValidatorFn, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'addcreditnotepromotiondialogservice-dialog',
  templateUrl: './addcreditnotepromotion.dialog.component.html',
  styleUrls: ['./addCreditNotePromotion.dialog.component.css'],
})
export class AddCreditNotePromotionDialog implements AfterViewInit {
  stringMaxLength: number = 250;
  pageLoaded = false;
  public addCreditNotePromotionForm: UntypedFormGroup;

  formControlPlaceholders = {
    startDate: $localize`Start Date`,
    endDate: $localize`End Date`,
    expiration: $localize`Expiration`,
  };

  constructor(
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddCreditNotePromotionDialog, AddCreditNotePromotionData>,
    @Inject(MAT_DIALOG_DATA) public data: AddCreditNotePromotionData
  ) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.addCreditNotePromotionForm = this.formBuilder.group({
        amount: ['', [Validators.required, Validators.pattern('[0-9]+(.[0-9][0-9]?)?')]],
        name: ['', [Validators.required, Validators.maxLength(this.stringMaxLength)]],
        code: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9ÄÖÜäöü]+$')]],
        maxRedemption: ['', [Validators.required]],
        durationStart: ['', [Validators.required]],
        durationEnd: ['', [Validators.required]],
        expiration: [''],
      });
      this.pageLoaded = true;
      this.addCreditNotePromotionForm.setValidators(this.customValidator());
    }, 0);
  }

  get f() {
    if (this.pageLoaded) {
      return this.addCreditNotePromotionForm.controls;
    }
  }
  onCancelClick(): void {
    this.dialogRef.close(undefined);
  }

  onConfirmClick(): void {
    this.data.name = this.addCreditNotePromotionForm.value.name;
    this.data.code = this.addCreditNotePromotionForm.value.code;
    this.data.amount = this.addCreditNotePromotionForm.value.amount.replace(',', '.');
    this.data.maxRedemption = this.addCreditNotePromotionForm.value.maxRedemption;
    this.data.durationStart = this.addCreditNotePromotionForm.value.durationStart;
    this.data.durationEnd = this.addCreditNotePromotionForm.value.durationEnd;
    this.data.expiration = this.addCreditNotePromotionForm.value.expiration;

    if (this.data.name.length > 0 && this.data.code.length > 0 && this.data.amount > 0 && this.data.maxRedemption > 0) {
      this.dialogRef.close(this.data);
    }
  }

  public customValidator(): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors => {
      const control1 = group.controls['durationStart'];
      const control2 = group.controls['durationEnd'];
      const control3 = group.controls['expiration'];
      if (control2.value != '' && control2.value != null && control1.value >= control2.value) {
        control2.setErrors({ notEquivalent: true });
      }

      if (control3.value != '' && control3.value != null && control2.value >= control3.value) {
        control3.setErrors({ notEquivalent: true });
      }
      return;
    };
  }
}

export class AddCreditNotePromotionData {
  name: string;
  code: string;
  amount: number;
  maxRedemption: number;
  durationStart: Date;
  durationEnd: Date;
  expiration: Date;
}
