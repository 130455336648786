<div class="creditnotepromotion-panel">
  <span class="cdk-visually-hidden" #tooltipClearFilter i18n>Clear All Filters</span>

  <mat-toolbar>
    <mat-toolbar-row>
      <div class="breadcrumb-container">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item" i18n>Credit Note Promotions</li>
          </ol>
        </nav>
      </div>
      <div class="content-button-margin">
        <button
          mat-raised-button
          color="primary"
          (click)="addCreditNotePromo()"
          [matTooltip]="tooltipClearFilter.innerHTML"
          matTooltipPosition="above"
        >
          <mat-icon>credit_card</mat-icon
          ><span i18n data-lokalise data-key="5096626174609327193">Launch New Promotion</span>
        </button>
        <button mat-raised-button (click)="columnSelector.show()">
          <mat-icon>view_column</mat-icon> <span i18n>Columns</span>
        </button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <app-columnselector [columns]="defaultColumns"></app-columnselector>
  <mat-divider></mat-divider>
</div>
<div class="pagedtable-container">
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner></mat-spinner>
  </div>
  <mat-sidenav-content>
    <div class="table-container">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortActive="CreationDate"
        matSortDirection="desc"
        (matSortChange)="sortData($event)"
        class="mat-elevation-z8"
      >
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row"></td>
        </ng-container>
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef><span i18n>position</span></th>
          <td mat-cell *matCellDef="let element">{{ element.position }}</td>
        </ng-container>

        <ng-container matColumnDef="PromoId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            <span i18n data-lokalise data-key="1693130091045373951">Credit Note ID</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <a routerLink="/{{ LOCALE_ID }}/creditnotepromotion/{{ element.promoId }}" routerLinkActive="active">{{
              element.promoId
            }}</a>
          </td>
        </ng-container>

        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear><span i18n>Name</span></th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="Code">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            <span i18n data-lokalise data-key="8186013988289067040">Code</span>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.code }}</td>
        </ng-container>

        <ng-container matColumnDef="Amount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear><span i18n>Amount</span></th>
          <td mat-cell *matCellDef="let element">{{ element.amount }}</td>
        </ng-container>

        <ng-container matColumnDef="MaxRedemptions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            <span i18n>Maximum Number of Redemptions</span>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.maxRedemptions }}</td>
        </ng-container>

        <ng-container matColumnDef="CreationDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            <span i18n data-lokalise data-key="6004681856910260407">Creation Date</span>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.creationDate }}</td>
        </ng-container>

        <ng-container matColumnDef="DurationStart">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            <span i18n data-lokalise data-key="7893980419919635421">Duration Start</span>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.durationStart }}</td>
        </ng-container>

        <ng-container matColumnDef="DurationEnd">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            <span i18n data-lokalise data-key="3308625527803189565">Duration End</span>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.durationEnd }}</td>
        </ng-container>

        <ng-container matColumnDef="Expiration">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            <span i18n data-lokalise data-key="3323137014509063489">Expiration</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.expiration != '0001-01-01T00:00:00'">{{ element.expiration }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear><span i18n>Status</span></th>
          <td mat-cell *matCellDef="let element">{{ element.status }}</td>
        </ng-container>
        <!-- <ng-container matColumnDef="Actions" stickyEnd>
          <th mat-header-cell *matHeaderCellDef ><span i18n>Actions</span></th>
          <td mat-cell *matCellDef="let element">
              <button *ngIf="element.status == 'Open'" 
                    class="cancel-button"
                    mat-icon-button color="error" 
                    (click)="withdrawTransaction(element)"
                    [matTooltip]="withdraw"
                    matTooltipPosition="above">
                    <mat-icon>delete_forever</mat-icon>
              </button>
          </td>
        </ng-container> -->
        <tr
          mat-header-row
          *matHeaderRowDef="columnSelector == undefined ? [] : columnSelector.displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: columnSelector == undefined ? [] : columnSelector.displayedColumns"
        ></tr>
      </table>
    </div>
  </mat-sidenav-content>
</div>
<mat-paginator
  [pageSize]="defaultSettings.table.pageSize"
  [pageSizeOptions]="defaultSettings.table.pageOptions"
  (page)="paginationChaged()"
  showFirstLastButtons
></mat-paginator>
