import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, config, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { User } from 'src/app/shared/models/user';
import { environment } from 'src/environments/environment';
import { AlertService } from '../alert/alert.service';
import { UserRole } from 'src/app/shared/models/userRole';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
    private activeUserLocalStorageName = 'currentUser';
    private activeUserSubject: BehaviorSubject<User>;
    public activeUser: Observable<User>;
    private alertService: AlertService;
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(private http: HttpClient, alertService: AlertService) {
        this.activeUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem(this.activeUserLocalStorageName)));
        this.activeUser = this.activeUserSubject.asObservable();

        this.alertService = alertService;
    }

    public getActiveUser(){
        return this.activeUserSubject.value;
    }

    public login(emailAddress: string, password: string){
        return this.http.post<any>(`${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/login`, JSON.stringify({ email: emailAddress, password: password }), { headers: this.headers })
            .pipe(map(user => {
                if(user) {
                    localStorage.setItem(this.activeUserLocalStorageName, JSON.stringify(user));
                    this.activeUserSubject.next(user);
                }
                else {
                    throw new Error("Invalid Login!");
                }

                return user;
            }));
    }

    public logout() {
        localStorage.removeItem(this.activeUserLocalStorageName);
        this.activeUserSubject.next(null);
    }
}
