<span class="cdk-visually-hidden" #tooltipClearFilter i18n>Clear All Filters</span>

<mat-toolbar>
  <mat-toolbar-row>
    <div class="breadcrumb-container">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a i18n-href [routerLink]="['/creditnotepromotions']" i18n>Credit Note Promotions</a>
          </li>
          <li class="breadcrumb-item" aria-current="page" i18n>Customers</li>
        </ol>
      </nav>
    </div>
    <!-- <div class="content-left-align content-button-margin">
    </div> -->
    <div class="content-button-margin">
      <button mat-raised-button color="primary" (click)="withdrawPromo()" *ngIf="!creditnotepromotion.withdrawn">
        <mat-icon>delete_forever</mat-icon> <span i18n>Withdraw Promotion</span>
      </button>
      <button mat-raised-button color="primary" (click)="createReport()">
        <mat-icon>save_alt</mat-icon><span i18n>Create Report</span>
      </button>
      <button mat-raised-button (click)="ShowColumnSelector()">
        <mat-icon>view_column</mat-icon><span i18n>Columns</span>
      </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<mat-card>
  <mat-card-content>
    <mat-grid-list cols="4" rowHeight="80px">
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div>
          <mat-form-field appearance="none">
            <mat-label i18n>Credit Note ID</mat-label>
            <input readonly="true" matInput value="{{ creditnotepromotion.promoId }}" />
          </mat-form-field>
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div>
          <mat-form-field appearance="none">
            <mat-label i18n>Name</mat-label>
            <input readonly="true" matInput value="{{ creditnotepromotion.name }}" />
          </mat-form-field>
        </div>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div>
          <mat-form-field appearance="none">
            <mat-label i18n>Status</mat-label>
            <input readonly="true" matInput value="{{ creditnotepromotion.status }}" />
          </mat-form-field>
        </div>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div>
          <mat-form-field appearance="none">
            <mat-label i18n>Amount</mat-label>
            <input readonly="true" matInput value="{{ creditnotepromotion.amount | currency: '' : '' }} CHF" />
          </mat-form-field>
        </div>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div>
          <mat-form-field appearance="none">
            <mat-label i18n>Maximum Cumulated Amount Possible</mat-label>
            <input readonly="true" matInput value="{{ creditnotepromotion.maxCumulatedAmountPossible }}" />
          </mat-form-field>
        </div>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div>
          <mat-form-field appearance="none">
            <mat-label i18n>Cumulated Amount Used</mat-label>
            <input readonly="true" matInput value="{{ creditnotepromotion.cumulatedAmountUsed | number: '1.2-2' }}" />
          </mat-form-field>
        </div>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div>
          <mat-form-field appearance="none">
            <mat-label i18n>Maximum Number Of Redemptions</mat-label>
            <input readonly="true" matInput value="{{ creditnotepromotion.maxRedemptions }}" />
          </mat-form-field>
        </div>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div>
          <mat-form-field appearance="none">
            <mat-label i18n>Current Number Of Redemptions</mat-label>
            <input readonly="true" matInput value="{{ creditnotepromotion.currentNumberOfRedemptions }}" />
          </mat-form-field>
        </div>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div></div>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div>
          <mat-form-field appearance="none">
            <mat-label i18n>Creation Date</mat-label>
            <input readonly="true" matInput value="{{ creditnotepromotion.creationDate }}" />
          </mat-form-field>
        </div>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div>
          <mat-form-field appearance="none">
            <mat-label i18n>Duration</mat-label>
            <input
              readonly="true"
              matInput
              value="{{ creditnotepromotion.durationStart }} - {{ creditnotepromotion.durationEnd }}"
            />
          </mat-form-field>
        </div>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div>
          <mat-form-field appearance="none">
            <mat-label i18n>Expiration</mat-label>
            <input
              readonly="true"
              matInput
              value="{{ creditnotepromotion.expiration }}"
              *ngIf="creditnotepromotion.expiration != '0001-01-01T00:00:00'"
            />
            <input
              readonly="true"
              matInput
              value="None"
              *ngIf="creditnotepromotion.expiration == '0001-01-01T00:00:00'"
            />
          </mat-form-field>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>
<mat-tab-group #customerTabs>
  <mat-tab i18n-label label="Customers">
    <app-creditnotepromotionscustomers [promotionId]="creditnotepromotion.promoId"></app-creditnotepromotionscustomers>
  </mat-tab>
</mat-tab-group>
