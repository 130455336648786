import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject, Component, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogServiceDialog, DialogServiceData } from 'src/app/shared/components/dialogservicedialog/dialogservice.dialog.component';

@Injectable({ providedIn: 'root' })
export class DialogService {
    constructor(public dialog: MatDialog) {}

    public yesNo(title: string = "Yes or No", message: string): Observable<boolean> {
        const dialogRef = this.dialog.open<DialogServiceDialog, DialogServiceData, string>(DialogServiceDialog,{
            width: '500px',
            data: { title: title, icon: 'warning', message: message, buttons: ['Yes', 'No']}
        });

        return dialogRef.afterClosed()
            .pipe(map(result => {
                return result === "Yes";
            }));
    }

    public yesConfirmation(title: string = "Yes or No", message: string, buttons: string[], confirmationButton: string): Observable<boolean> {
        const dialogRef = this.dialog.open<DialogServiceDialog, DialogServiceData, string>(DialogServiceDialog,{
            width: '500px',
            data: { title: title, icon: 'warning', message: message, buttons: buttons}
        });

        return dialogRef.afterClosed()
            .pipe(map(result => {
                return result === confirmationButton;
            }));
    }

    public show(message: string, buttons: string[], icon: string = "error_outline", title: string = "Administration GUI", width: string = '500px'): Observable<string>
    {
        const dialogRef = this.dialog.open<DialogServiceDialog, DialogServiceData, string>(DialogServiceDialog,{
            width: width,
            data: { title: title, icon: icon, message: message, buttons: ['Yes', 'No']}
        });

        return dialogRef.afterClosed();
    }
}