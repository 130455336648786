import { Injectable } from '@angular/core';
import { AlertService } from '../alert/alert.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { KeyValue } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor(private http: HttpClient, private alertService: AlertService) { 
  }

  public getParkingProviders(customerReferenceID: string): Observable<Array<LookupItem>>
  {
    let baseEndpoint = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/lookup/parkingproviders`;
    let queryString:[string,string][] = [ 
      ["customerReferenceID", customerReferenceID],
    ];

    let url = `${baseEndpoint}?${queryString.map(n=> n.join(`=`)).join(`&`)}`;
      return this.http.get<Array<LookupItem>>(url, { headers: this.headers })
        .pipe(map(a => {
          return a;
        }));
  }
}

export interface LookupItem {
  name: string;
  value: string;
}