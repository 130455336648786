<h4 mat-dialog-title><span class="span-title"><mat-icon class="dialog-icon">help</mat-icon>{{data.title}}</span></h4>
<form [formGroup]="addCommentForm"  *ngIf="pageLoaded">
  <div mat-dialog-content> 
    <mat-form-field style="width: 100%;">
      <mat-label><span i18n>Reason</span></mat-label>
      <textarea matInput formControlName="comment" required [maxlength]="reasonMaxLength"></textarea>
      <mat-error *ngIf="f.comment.invalid">
        <span *ngIf="f.comment.hasError('required')" i18n>You must enter a value</span>
      </mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onConfirmClick()" [disabled]="f.comment.invalid" i18n>Confirm</button>
    <button mat-button (click)="onCancelClick()" i18n>Cancel</button>
  </div>
</form>