import { Component, Input, Output } from '@angular/core';
import { TableColumn } from '../../models/tableColumn';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-columnselector',
  templateUrl: './columnselector.component.html',
  styleUrls: ['./columnselector.compoment.scss'],
})
export class ColumnSelectorComponent {
  ngAfterViewInit() {
    setTimeout(() => {
      this.updateColumList();
    }, 0);
  }

  public columnsOpened: boolean = false;
  public columnList: any;

  public displayedColumns: string[] = [];

  @Input()
  public columns: TableColumn[];

  @Output()
  public onChange: EventEmitter<string[]> = new EventEmitter();

  public show() {
    this.columnsOpened = true;
  }

  GenerateColumnListing(visibleFieldNames: string[]): TableColumn[] {
    let result = this.columns;
    if (visibleFieldNames) {
      result.forEach((m) => (m.visible = visibleFieldNames.indexOf(m.fieldName) > 0 || m.alwaysVisibility));
    }
    return result;
  }

  getDisplayedColumns(): string[] {
    const result = new Array<string>();
    result.push('select');

    const selected = this.columns.filter((m) => m.visible);
    selected.forEach((m) => result.push(m.fieldName));

    return result;
  }

  updateColumList() {
    this.columns = this.GenerateColumnListing(this.columnList);
    this.displayedColumns = this.getDisplayedColumns();
    this.onChange.emit(this.displayedColumns);
  }
}
