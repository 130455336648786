import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CreditNotePromotionCustomerPageItem } from 'src/app/shared/models/creditnotepromotioncustomersPageItem';
import { CreditNotePromotionService } from 'src/app/core/services/creditnotepromotion.service';
import { PageMetaData } from 'src/app/shared/models/queryResult';
import { TableColumn } from 'src/app/shared/models/tableColumn';
import { Sort } from '@angular/material/sort';
import { SortDirection } from 'src/app/shared/enums/sortDirectionEnum';
import { ColumnSelectorComponent } from 'src/app/shared/components/columnSelector/columnselector.component';
import { DialogService } from 'src/app/core/alert/dialog.service';
import { AddCreditNotePromotionDialog, AddCreditNotePromotionData } from 'src/app/shared/components/addCreditNotePromotionDialog/addcreditnotepromotion.dialog.component';
import { AlertService } from 'src/app/core/alert/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-creditnotepromotionscustomers',
  templateUrl: './creditnotepromotionscustomers.component.html',
  styleUrls: ['./creditnotepromotionscustomers.component.scss']
})
export class CreditnotePromotionsCustomersComponent implements OnInit {

  dataSource: CreditNotePromotionCustomerPageItem[] = [];
  dataSoureMetaData: PageMetaData;
  public isLoadingResults = false;
  public creditNotePromotionService: CreditNotePromotionService;
  sort: string = "creditNoteActivationDate";
  sortDirection: SortDirection = SortDirection.Descending;
  defaultSettings: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(ColumnSelectorComponent) columnSelector: ColumnSelectorComponent;
  public creditnotepromotion: CreditNotePromotionCustomerPageItem = new CreditNotePromotionCustomerPageItem();
  promotionId: string;

  constructor(
    creditNotePromotionService: CreditNotePromotionService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.promotionId = this.activatedRoute.snapshot.params['promotionId']
    this.defaultSettings = environment.defaultSettings;
    this.creditNotePromotionService = creditNotePromotionService;
  }


  ngOnInit() {
    this.fetchData(this.promotionId, environment.defaultSettings.table.pageSize, 1);
  }

  fetchData(promotionid, pageSize, pageNumber) {

    this.isLoadingResults = true;
    this.creditNotePromotionService.getAllCustomers(promotionid, pageSize, pageNumber, this.sort, this.sortDirection)
      .subscribe(result => {
        this.isLoadingResults = false;
        this.dataSource = result.items;
        this.dataSoureMetaData = result.pageMetaData;
        this.paginator.length = result.pageMetaData.totalItemCount;
        this.paginator.pageIndex = result.pageMetaData.pageNumber - 1;
      });
  }

  paginationChaged() {
    this.fetchData(this.promotionId, this.paginator.pageSize, this.paginator.pageIndex + 1);
  }

  public defaultColumns: TableColumn[] = [
    { visible: true, alwaysVisibility: true, fieldName: `customerReferenceID`, displayText: $localize`Customer Reference ID` },
    { visible: true, alwaysVisibility: true, fieldName: `tcsMemberID`, displayText: $localize`TCS Member ID` },
    { visible: true, alwaysVisibility: true, fieldName: `firstName`, displayText: $localize`First Name` },
    { visible: true, alwaysVisibility: false, fieldName: `lastName`, displayText: $localize`Last Name` },
    { visible: true, alwaysVisibility: false, fieldName: `lastRegisteredLicensePlate`, displayText: $localize`Last Registered License Plate` },
    { visible: true, alwaysVisibility: false, fieldName: `emailAddress`, displayText: $localize`Email Address` },
    { visible: true, alwaysVisibility: false, fieldName: `creditNoteActivationDate`, displayText: $localize`Credit Note Activation Date` },
    { visible: true, alwaysVisibility: false, fieldName: `creditNoteAmountRemaining`, displayText: $localize`Credit Note Amount Remaining` }
  ];

  sortData(sort: Sort) {
    this.sort = sort.active;
    this.sortDirection = sort.direction == "asc" ? SortDirection.Ascending : SortDirection.Descending;
    this.fetchData(this.promotionId, this.paginator.pageSize, this.paginator.pageIndex + 1);
  }
}