import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreditNotePromotionService } from 'src/app/core/services/creditnotepromotion.service';
import { BlockingStatus } from '../../enums/blockingStatusEnum';
import { CreditNotePromotionPageItem } from '../../models/creditnotePromotionPageItem';
import { UpdateStatus } from '../../models/updateStatus';

@Component({
  selector: 'app-withdrawpromotiondialog',
  templateUrl: './withdrawpromotiondialog.component.html',
  styleUrls: ['./withdrawpromotiondialog.component.scss']
})
export class WithdrawPromotionDialogComponent implements OnInit {

  constructor(private creditNotePromotionService: CreditNotePromotionService,
    public dialogRef: MatDialogRef<CreditNotePromotionPageItem>,
    @Inject(MAT_DIALOG_DATA) public data: CreditNotePromotionPageItem) { }

  ngOnInit(): void {
  }

  onSubmit() {
    var obj = new UpdateStatus();
    this.creditNotePromotionService.withdrawPromotion(this.data.promoId)
    .subscribe(result => {
        this.dialogRef.close(obj);
    });
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
