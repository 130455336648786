<app-columnselector [columns]="defaultColumns"></app-columnselector>

<mat-divider></mat-divider>
<div class="pagedtable-container">
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner></mat-spinner>
  </div>
  
  <mat-sidenav-content >
    <div class="table-container">
      <table mat-table [dataSource]="dataSource" class="content-table" matSort matSortActive="creditNoteActivationDate" matSortDirection="desc" (matSortChange)="sortData($event)">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
          </th>
          <td mat-cell *matCellDef="let row"> 
          </td>
        </ng-container>
        <!-- Position Column -->
        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef><span i18n>position</span></th>
            <td mat-cell *matCellDef="let element"> {{element.position}} </td>
          </ng-container>
        <ng-container matColumnDef="customerReferenceID">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear><span i18n>Customer Reference ID</span> </th>
          <td mat-cell *matCellDef="let element"> <a routerLink="/customers/{{element.customerReferenceID}}" routerLinkActive="active">{{element.customerReferenceID}}</a> </td>
        </ng-container>
        <ng-container matColumnDef="tcsMemberID">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear><span i18n>TCS Member ID</span></th>
          <td mat-cell *matCellDef="let element"> {{element.tcsMemberID}} </td>
        </ng-container>
        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear><span i18n>First Name</span></th>
          <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
        </ng-container>
        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear><span i18n>Last Name</span></th>
          <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
        </ng-container>
        <ng-container matColumnDef="lastRegisteredLicensePlate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear><span i18n>Last Registered License Plate</span></th>
          <td mat-cell *matCellDef="let element"> {{element.lastRegisteredLicensePlate}} </td>
        </ng-container>
        <ng-container matColumnDef="emailAddress">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear><span i18n>Email Address</span></th>
          <td mat-cell *matCellDef="let element"> {{element.emailAddress}} </td>
        </ng-container>
        <ng-container matColumnDef="creditNoteActivationDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear><span i18n>Credit Note Activation Date</span></th>
          <td mat-cell *matCellDef="let element"> {{element.creditNoteActivationDate | date: 'yyyy-MM-ddTHH:mm:ss'}} </td>
        </ng-container>
        
        <ng-container matColumnDef="creditNoteAmountRemaining">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear><span i18n>Credit Note Amount Remaining</span></th>
          <td mat-cell *matCellDef="let element" > {{element.creditNoteAmountRemaining | number : '1.2-2'}} </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="(columnSelector == undefined ? [] : columnSelector.displayedColumns); sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: (columnSelector == undefined ? [] : columnSelector.displayedColumns);">
        </tr>
      </table>
    </div>
    
  </mat-sidenav-content >
  <mat-paginator class="paginator" [pageSize]="defaultSettings.table.pageSize" [pageSizeOptions]="defaultSettings.table.pageOptions" (page) = "paginationChaged()" showFirstLastButtons></mat-paginator>
</div>


