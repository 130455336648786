export class CustomerInformation {
    position: number;
    customerReferenceID: string;
    tcsMemberNumber: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    totalCreditNote: number;
    totalPayable: number;
    isBlocked: boolean;
  }