import { Injectable } from '@angular/core';
import { AlertService } from '../alert/alert.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ParkingTransactionHistory } from 'src/app/components/parkingtransactionhistorylisting/parkingtransactionhistorylisting.component';
import { environment } from 'src/environments/environment';
import { QueryResult } from 'src/app/shared/models/queryResult';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { KeyValue } from '@angular/common';
import { CancelTransactionResponseModel } from 'src/app/shared/models/cancelTransactionResponseModel';

@Injectable({
  providedIn: 'root'
})
export class ParkingTransactionHistoryService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor(private http: HttpClient, private alertService: AlertService) { 
  }

  public getAll(
    parkingTransactionId: string,
    pageSize: number, pageNumber: number): Observable<QueryResult<ParkingTransactionHistory>>
  {
    let baseEndpoint = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/parkingTransactionHistories`;
    let queryString:[string,string][] = [ 
          ["parkingTransactionId", parkingTransactionId],
          ["pageSize", `${pageSize}`],
          ["pageNumber",`${pageNumber}`]
    ];

    let url = `${baseEndpoint}?${queryString.map(n=> n.join(`=`)).join(`&`)}`;
      return this.http.get<QueryResult<ParkingTransactionHistory>>(url, { headers: this.headers })
        .pipe(map(a => {
          return a;
        }));
  }
}
