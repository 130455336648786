import { Component, Inject, AfterViewInit } from '@angular/core';
import { FormControl, Validators, FormBuilder, UntypedFormGroup } from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-addcommentdialog',
  templateUrl: './addcomment.dialog.component.html',
  styleUrls: ['./addcomment.dialog.component.scss']
})
export class AddCommentDialog implements AfterViewInit {
  reasonMaxLength: number = 250;
  pageLoaded = false;
  public addCommentForm: UntypedFormGroup;

  constructor(public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddCommentDialog, AddCommentData>,
    @Inject(MAT_DIALOG_DATA) public data: AddCommentData) {

  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.addCommentForm = this.formBuilder.group({
        comment: ['', [Validators.required, Validators.maxLength(this.reasonMaxLength)]]
      })
      this.pageLoaded = true;
    }, 0);
  }

  get f() {
    if (this.pageLoaded) {
      return this.addCommentForm.controls;
    }
  }

  onCancelClick(): void {
    this.dialogRef.close(undefined);
  }

  onConfirmClick(): void {
    this.data.comment = this.addCommentForm.value.comment;

    if (this.data.comment.trim().length > 0) {
      this.dialogRef.close(this.data);
    }
  }

}
export class AddCommentData {
  comment: string;
  title: string;
}
