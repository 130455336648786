<app-columnselector [columns]="defaultColumns"></app-columnselector>

<span class="cdk-visually-hidden" #tooltipCancel i18n>Cancel Transaction</span>
<span class="cdk-visually-hidden" #tooltipWithdraw i18n>Withdraw Transaction</span>
<span class="cdk-visually-hidden" #tooltipView i18n>View History</span>

<form [formGroup]="filterForm" (ngSubmit)="fetchData(paginator.pageSize, 1)" class="form-root">
  <mat-toolbar class="content-formfield-margin control-toolbar-root">
    <mat-form-field class="search-field">
      <span matPrefix><mat-icon>search</mat-icon></span>
      <input
        matInput
        i18n-placeholder
        placeholder="Filter by keyword"
        formControlName="filterKeyword"
        type="text"
        [(ngModel)]="keywordText"
        (keyup)="keywordChanged($event)"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label i18n>Parking Provider</mat-label>
      <mat-select
        matNativeControl
        formControlName="parkingProvider"
        [(ngModel)]="selectedParkingProvider"
        (selectionChange)="handleFilterFormChange()"
      >
        <mat-option *ngFor="let item of parkingProviderList" [value]="item.value">
          {{ item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="clearable">
      <mat-form-field>
        <mat-label i18n="P&P Registration Date">P&P Registration Date</mat-label>
        <mat-date-range-input [rangePicker]="parkingDate" formGroupName="parkingDate">
          <input matStartDate formControlName="start" />
          <input matEndDate (dateChange)="handleFilterFormChange()" formControlName="end" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="parkingDate" />
        <mat-date-range-picker #parkingDate> </mat-date-range-picker>
      </mat-form-field>
      <mat-icon
        *ngIf="filterForm.value.parkingDate.start && filterForm.value.parkingDate.end"
        class="clear-icon"
        (click)="clearDate('parkingDate')"
        >close</mat-icon
      >
    </div>
    <mat-form-field>
      <mat-label i18n>Status</mat-label>
      <mat-select
        matNativeControl
        formControlName="status"
        [(ngModel)]="transactionStatus"
        (selectionChange)="handleFilterFormChange()"
      >
        <mat-option *ngFor="let item of parkingTransactionStatus" [value]="item.value">
          {{ item.text }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label i18n>Payment Mean</mat-label>
      <mat-select
        matNativeControl
        formControlName="paymentMeans"
        [(ngModel)]="paymentMeans"
        (selectionChange)="handleFilterFormChange()"
      >
        <mat-option *ngFor="let item of paymentMeanList" [value]="item.value">
          {{ item.text }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="clearable">
      <mat-form-field>
        <mat-label i18n="Modified Date">Modified Date</mat-label>
        <mat-date-range-input [rangePicker]="modifiedDate" formGroupName="modifiedDate">
          <input matStartDate formControlName="start" />
          <input matEndDate (dateChange)="handleFilterFormChange()" formControlName="end" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="modifiedDate" />
        <mat-date-range-picker #modifiedDate> </mat-date-range-picker>
      </mat-form-field>
      <mat-icon
        *ngIf="filterForm.value.modifiedDate.start && filterForm.value.modifiedDate.end"
        class="clear-icon"
        (click)="clearDate('modifiedDate')"
        >close
      </mat-icon>
    </div>
  </mat-toolbar>
</form>
<mat-divider></mat-divider>
<div class="pagedtable-container">
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner></mat-spinner>
  </div>

  <mat-sidenav-content>
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row"></td>
      </ng-container>
      <!-- Position Column -->
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef><span i18n>position</span></th>
        <td mat-cell *matCellDef="let element">{{ element.position }}</td>
      </ng-container>
      <ng-container matColumnDef="ParkingReferenceID">
        <th mat-header-cell *matHeaderCellDef><span i18n>Reference ID</span></th>
        <td mat-cell *matCellDef="let element">
          <a
            routerLink="/customers/{{ element.customerReferenceID }}/parkings/{{
              element.parkingTransactionReferenceID
            }}"
            routerLinkActive="active"
            >{{ element.parkingTransactionReferenceID }}</a
          >
        </td>
      </ng-container>
      <ng-container matColumnDef="VehicleNumber">
        <th mat-header-cell *matHeaderCellDef><span i18n>License Plate</span></th>
        <td mat-cell *matCellDef="let element">{{ element.carPlateNumber }}</td>
      </ng-container>
      <ng-container matColumnDef="VehicleType">
        <th mat-header-cell *matHeaderCellDef><span i18n>Vehicle Type</span></th>
        <td mat-cell *matCellDef="let element">{{ element.vehicleType }}</td>
      </ng-container>
      <ng-container matColumnDef="ParkingLocationDescription">
        <th mat-header-cell *matHeaderCellDef><span i18n>Parking Location</span></th>
        <td mat-cell *matCellDef="let element">{{ element.parkingLocationDescription }}</td>
      </ng-container>
      <ng-container matColumnDef="ParkingLocationCoordinate">
        <th mat-header-cell *matHeaderCellDef><span i18n>Coordinates</span></th>
        <td mat-cell *matCellDef="let element">
          <a
            class="link-coordinate"
            i18n-matTooltip
            matTooltip="Go to map"
            *ngIf="element.geoFeatureLatitude && element.geoFeatureLongitude"
            (click)="goToGoogleMapLink(element.geoFeatureLatitude, element.geoFeatureLongitude)"
            ><img src="../assets/google-map-icon.svg" class="google-map-icon"
          /></a>
        </td>
      </ng-container>
      <ng-container matColumnDef="ParkingProvider">
        <th mat-header-cell *matHeaderCellDef><span i18n>Parking Provider</span></th>
        <td mat-cell *matCellDef="let element">{{ element.parkingProviderID }}</td>
      </ng-container>
      <ng-container matColumnDef="ParkingStartDate">
        <th mat-header-cell *matHeaderCellDef><span i18n>Start Date</span></th>
        <td mat-cell *matCellDef="let element">{{ element.parkingStartDateText }}</td>
      </ng-container>

      <ng-container matColumnDef="ParkingStart">
        <th mat-header-cell *matHeaderCellDef><span i18n>Start Time</span></th>
        <td mat-cell *matCellDef="let element">{{ element.parkingStart }}</td>
      </ng-container>

      <ng-container matColumnDef="ParkingEndDate">
        <th mat-header-cell *matHeaderCellDef><span i18n>End Date</span></th>
        <td mat-cell *matCellDef="let element">{{ element.parkingEndDateText }}</td>
      </ng-container>

      <ng-container matColumnDef="ParkingEnd">
        <th mat-header-cell *matHeaderCellDef><span i18n>End Time</span></th>
        <td mat-cell *matCellDef="let element">{{ element.parkingEnd }}</td>
      </ng-container>

      <ng-container matColumnDef="TotalParkingDuration">
        <th mat-header-cell *matHeaderCellDef><span i18n>Total Duration</span></th>
        <td mat-cell *matCellDef="let element">{{ element.durationText }}</td>
      </ng-container>

      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef><span i18n>Status</span></th>
        <td mat-cell *matCellDef="let element">{{ element.statusText }}</td>
      </ng-container>
      <ng-container matColumnDef="PaymentMeans">
        <th mat-header-cell *matHeaderCellDef><span i18n>Payment Mean</span></th>
        <td mat-cell *matCellDef="let element">{{ element.meansOfPaymentDisplayText }}</td>
      </ng-container>
      <ng-container matColumnDef="PaymentMeanNumber">
        <th mat-header-cell *matHeaderCellDef><span i18n>Credit Card Number</span></th>
        <td mat-cell *matCellDef="let element">{{ element.paymentMeanNumberDisplayText }}</td>
      </ng-container>
      <ng-container matColumnDef="Amount">
        <th mat-header-cell *matHeaderCellDef><span i18n>Amount</span></th>
        <td mat-cell *matCellDef="let element">{{ element.amount | currency: '' : '' }}</td>
      </ng-container>
      <ng-container matColumnDef="Payable">
        <th mat-header-cell *matHeaderCellDef><span i18n>Payable</span></th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.payable == 0">-</span
          ><span *ngIf="element.payable != 0">{{ element.payable | currency: '' : '' }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="Comment">
        <th mat-header-cell *matHeaderCellDef><span i18n>Comment</span></th>
        <td mat-cell *matCellDef="let element">{{ element.comment }}</td>
      </ng-container>
      <ng-container matColumnDef="ModifiedBy">
        <th mat-header-cell *matHeaderCellDef><span i18n>Modified By</span></th>
        <td mat-cell *matCellDef="let element">{{ element.modifiedBy }}</td>
      </ng-container>
      <ng-container matColumnDef="ModifiedDate">
        <th mat-header-cell *matHeaderCellDef><span i18n>Modified Date</span></th>
        <td mat-cell *matCellDef="let element">{{ element.modifiedDateText }}</td>
      </ng-container>
      <ng-container matColumnDef="Actions" stickyEnd>
        <th mat-header-cell *matHeaderCellDef><span i18n>Actions</span></th>
        <td mat-cell *matCellDef="let element">
          <a
            mat-icon-button
            color="primary"
            [routerLink]="['/customers', customerReferenceId, 'parkings', element.parkingTransactionReferenceID]"
            [matTooltip]="tooltipView.innerHTML"
            matTooltipPosition="above"
          >
            <mat-icon>history</mat-icon>
          </a>
          <button
            *ngIf="element.status == 'PAYABLE'"
            class="cancel-button"
            mat-icon-button
            color="error"
            (click)="cancelTransaction(element)"
            [matTooltip]="toolTipText(element)"
            matTooltipPosition="above"
          >
            <mat-icon>cancel</mat-icon>
          </button>
          <button
            *ngIf="element.status == 'COLLECTED' || element.status == 'PARTIALLYCOLLECTED'"
            mat-icon-button
            color="error"
            (click)="withdrawTransaction(element)"
            [matTooltip]="toolTipText(element)"
            matTooltipPosition="above"
          >
            <mat-icon>cancel_presentation</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr
        mat-header-row
        *matHeaderRowDef="columnSelector == undefined ? [] : columnSelector.displayedColumns; sticky: true"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columnSelector == undefined ? [] : columnSelector.displayedColumns"
      ></tr>
    </table>
  </mat-sidenav-content>
  <mat-paginator
    [pageSize]="defaultSettings.table.pageSize"
    [pageSizeOptions]="defaultSettings.table.pageOptions"
    (page)="handlePaginationChange()"
    showFirstLastButtons
  ></mat-paginator>
</div>
