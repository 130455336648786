// This environment is intended for running on INT. After logging in at TCS' new AD, it redirects to INT.
import { EnvironmentEnum } from 'src/app/shared/enums/environmentsEnum';

export const environment = {
  production: true,
  backendApiUrl: 'https://parking-cc-int.tcsgroup.ch/parkingservice',
  backendApiVersion: 1,
  clientId: '02d36d27-cc1c-46fd-81ea-de221953e839',
  tenantID: 'b6ad2cf5-3533-463d-8986-d1c95ab386fb',
  get authority() { return `https://login.microsoftonline.com/${this.tenantID}` },
  postLogoutRedirectUri: 'https://parking-cc-int.tcsgroup.ch',
  redirectUri: "https://parking-cc-int.tcsgroup.ch",
  defaultSettings: {
    table: {
      pageSize: 50,
      pageOptions: [50, 100, 150]
    },
    dateMomentFormat: "DD.MM.YYYY"
  },
  isLocal: false,
  lokaliseProjectId: '477393515f8e4893035fc8.81283625',
  name: EnvironmentEnum.Internal
};