<div [class.dark-theme]="darktheme" style="widows: 100%">
  <!-- Toolbar -->
  <mat-toolbar color="primary">
    <button class="mat-button nav-hamburger" mat-button title="menu" (click)="opened = !opened">
      <mat-icon class="hamburger">menu</mat-icon>
    </button>
    <img src="../assets/logo.png" class="tcs-nav-logo" (click)="navigate('')" />
    <div><span>TCS Parking Service - Administration GUI</span></div>
    <span class="filler"></span>
    <a href="/de/{{ route }}"><img src="../assets/de_flag.svg" class="tcs-nav-logo" /></a>
    <a href="/fr/{{ route }}"><img src="../assets/fr_flag.svg" class="tcs-nav-logo" /></a>
    <a href="/en{{ route }}"><img src="../assets/uk_flag.svg" class="tcs-nav-logo" /></a>
  </mat-toolbar>

  <mat-sidenav-container class="main-container">
    <mat-sidenav autoFocus="false" mode="over" [(opened)]="opened" style="width: 250px">
      <mat-list>
        <mat-list-item>
          <div class="list-item-content-container">
            <mat-icon>person</mat-icon> <span i18n>Hello</span>
            <span *ngIf="!isLocal">{{ userName }}!</span>
          </div>
        </mat-list-item>
      </mat-list>
      <mat-nav-list>
        <mat-list-item class="list-item-checkbox-container">
          <div class="list-item-content-container">
            <mat-checkbox [(ngModel)]="darktheme" (change)="darkModeChange($event)" />
            <span i18n>Dark Mode</span>
          </div>
        </mat-list-item>

        <a mat-list-item (click)="navigate('customers')">
          <div class="list-item-content-container"><mat-icon>account_box</mat-icon> <span i18n>Customers</span></div>
        </a>
        <a mat-list-item (click)="navigate('creditnotepromotions')">
          <div class="list-item-content-container">
            <mat-icon>credit_card</mat-icon> <span i18n>Credit Note Promotions</span>
          </div>
        </a>

        <a mat-list-item (click)="toggleReport()">
          <div class="list-item-content-container"><mat-icon>assessment</mat-icon> <span i18n>Reports</span></div>
        </a>
        <mat-list style="margin-left: 30px" *ngIf="isReportVisible">
          <a mat-list-item (click)="exportRevenueReport()">
            <span i18n>Revenue</span>
          </a>
          <a mat-list-item (click)="exportCustomerParkingTransactionReport()">
            <span i18n>Parking transactions</span>
          </a>
          <a mat-list-item (click)="exportFailedCustomerParkingTransactionReport()">
            <span i18n>Failed Parking Transactions</span>
          </a>
          <a mat-list-item (click)="exportMarketingReport()">
            <span i18n>Marketing Report</span>
          </a>
        </mat-list>
        <mat-divider></mat-divider>

        <a mat-list-item (click)="logout()">
          <div class="list-item-content-container"><mat-icon>exit_to_app</mat-icon> <span i18n>Logout</span></div>
        </a>
      </mat-nav-list>
    </mat-sidenav>

    <!-- Main content -->
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <footer [ngClass]="{ 'pb-5': displayLokaliseToolbar === true }">
    <div class="text-center">
      <span i18n>© {{ copyrightYear }} Copyright: </span>
      <a href="https://www.tcs.ch/de/"><span i18n>Touring Club Schweiz</span></a>
    </div>
  </footer>
</div>
