import { Component, OnInit, ViewChild, Input, LOCALE_ID, Inject } from '@angular/core';
import { ColumnSelectorComponent } from 'src/app/shared/components/columnSelector/columnselector.component';
import { TableColumn } from 'src/app/shared/models/tableColumn';
import { CollectivePaymentPageItem } from 'src/app/shared/models/collectivePaymentPageItem';
import { PageMetaData } from 'src/app/shared/models/queryResult';
import { collectivePaymentService } from 'src/app/core/services/collectivePayment.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { EnumDropdown } from 'src/app/shared/models/enumDropdown';
import { CollectivePaymentTransactionItem } from 'src/app/shared/models/collectivePaymentTransactionItem';
import { CollectivePaymentTransactionDialog } from 'src/app/shared/components/collectivepaymenttransactiondialog/collectivepaymenttransaction.dialog.component';
import { CollectivePaymentListingFilterI } from 'src/app/shared/models/collectivePaymentListingFilter';

@Component({
  selector: 'app-collectivepaymentlisting',
  templateUrl: './collectivepaymentlisting.component.html',
  styleUrls: ['./collectivepaymentlisting.component.scss'],
})
export class CollectivepaymentlistingComponent implements OnInit {
  collectivePaymentDataSource: CollectivePaymentPageItem[] = [];
  collectivePaymentTransactionDataSource: CollectivePaymentTransactionItem[] = [];
  collectivePaymentStatusEnum: EnumDropdown[] = [];
  collectivePaymentTriggerTypeEnum: EnumDropdown[] = [];
  private dataSoureMetaData: PageMetaData;

  private fetchDataProcessId = 1;
  keywordText: string;
  isLoadingResults = false;
  private filterChangedTimeout;
  public filterForm: FormGroup<CollectivePaymentListingFilterI>;
  public oldKeywordValue: string;

  defaultSettings: any;
  status: any;
  trigger: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(ColumnSelectorComponent) columnSelector: ColumnSelectorComponent;
  @ViewChild('date') date: MatDateRangePicker<Moment>;

  @Input()
  customerReferenceId: string;

  constructor(
    private collectivePaymentService: collectivePaymentService,
    private dialog: MatDialog,
    filterFormBuilder: FormBuilder,
    @Inject(LOCALE_ID) public locale: string
  ) {
    this.defaultSettings = environment.defaultSettings;

    this.buildFormGroup(filterFormBuilder);
  }

  ngOnInit(): void {
    this.fetchData(environment.defaultSettings.table.pageSize, 1);
    this.fetchCollectivePaymentStatus();
    this.fetchCollectivePaymentTriggerType();
  }

  private buildFormGroup(filterFormBuilder: FormBuilder) {
    this.filterForm = filterFormBuilder.group({
      filterKeyword: new FormControl(''),
      status: new FormControl(''),
      triggerType: new FormControl(''),
      date: filterFormBuilder.group({
        start: new FormControl(''),
        end: new FormControl(''),
      }),
    });
  }

  public handleFilterFormChange() {
    this.fetchData(this.paginator.pageSize, 1);
  }

  public handlePaginationChange() {
    this.fetchData(this.paginator.pageSize, this.paginator.pageIndex + 1);
  }

  public clearFilters() {
    this.filterForm.get('filterKeyword').setValue('');
    this.filterForm.get('status').setValue('');
    this.filterForm.get('triggerType').setValue('');
    this.clearDate('date');

    this.handleFilterFormChange();
  }

  public clearDate(key: string) {
    this.filterForm.get(key).get('start').setValue('');
    this.filterForm.get(key).get('end').setValue('');
    this.handleFilterFormChange();
  }

  fetchData(pageSize: number, pageNumber: number) {
    const fetchDataProcessId = this.fetchDataProcessId + 1;
    this.fetchDataProcessId = fetchDataProcessId;

    const collectionStart = this.formatDate(this.filterForm.value.date.start);
    const collectionEnd = this.formatDate(this.filterForm.value.date.end);

    let keywordText = this.filterForm.get('filterKeyword').value;
    let status = this.filterForm.get('status').value;
    let trigger = this.filterForm.get('triggerType').value;

    if (status == 'ALL') {
      status = null;
    }

    if (trigger == 'ALL') {
      trigger = null;
    }

    this.isLoadingResults = true;
    this.collectivePaymentService
      .getAllCollectivePayments(
        this.customerReferenceId,
        keywordText,
        collectionStart,
        collectionEnd,
        status,
        trigger,
        pageSize,
        pageNumber
      )
      .subscribe((result) => {
        if (this.fetchDataProcessId != fetchDataProcessId) {
          return; //skip when the processid is old
        }

        this.isLoadingResults = false;
        this.collectivePaymentDataSource = result.items;
        this.dataSoureMetaData = result.pageMetaData;

        this.paginator.length = result.pageMetaData.totalItemCount;
        this.paginator.pageIndex = result.pageMetaData.pageNumber - 1;
      });
  }

  /**
   * Type guard for distinguishing between `string` and `Moment` types.
   *
   * @param value the value to check
   * @returns boolean indicating whether the variable is a Moment
   */
  private isMoment(value: string | Moment): value is Moment {
    return typeof value !== 'string';
  }

  private formatDate(date: string | Moment) {
    if (!this.isMoment(date)) {
      return date;
    }
    return date.format(environment.defaultSettings.dateMomentFormat);
  }

  fetchCollectivePaymentStatus() {
    this.collectivePaymentService.getCollectivePaymentStatus().subscribe((result) => {
      this.collectivePaymentStatusEnum = result.items;
    });
  }

  fetchCollectivePaymentTriggerType() {
    this.collectivePaymentService.getCollectivePaymentTriggerType().subscribe((result) => {
      this.collectivePaymentTriggerTypeEnum = result.items;
    });
  }

  keywordChanged(event: KeyboardEvent) {
    if (this.oldKeywordValue == this.keywordText) {
      return;
    }

    this.oldKeywordValue = this.keywordText;

    if (this.filterChangedTimeout) {
      clearTimeout(this.filterChangedTimeout);
    }

    if (event.keyCode === 13) {
      this.fetchData(this.paginator.pageSize, 1);
      return;
    }

    this.filterChangedTimeout = setTimeout(() => {
      this.fetchData(this.paginator.pageSize, 1);
    }, 1000);
  }

  viewCollectivePaymentTransaction(id: number) {
    this.isLoadingResults = true;
    this.collectivePaymentService
      .getAllCollectivePaymentTransactions(this.customerReferenceId, id)
      .subscribe((result) => {
        this.dialog.open<
          CollectivePaymentTransactionDialog,
          CollectivePaymentTransactionItem[],
          CollectivePaymentTransactionItem[]
        >(CollectivePaymentTransactionDialog, {
          width: '50%',
          data: result.items,
        });

        this.isLoadingResults = false;
      });
  }

  public defaultColumns: TableColumn[] = [
    {
      visible: true,
      alwaysVisibility: true,
      fieldName: `BatchReferenceId`,
      displayText: $localize`Batch Reference ID`,
    },
    { visible: true, alwaysVisibility: false, fieldName: `Trigger`, displayText: $localize`Trigger` },
    { visible: true, alwaysVisibility: false, fieldName: `MeansOfPayment`, displayText: $localize`Means Of Payment` },
    { visible: true, alwaysVisibility: false, fieldName: `TotalAmount`, displayText: $localize`Total Amount` },
    { visible: true, alwaysVisibility: false, fieldName: `Status`, displayText: $localize`Status` },
    { visible: true, alwaysVisibility: false, fieldName: `SaferPayRemarks`, displayText: $localize`Remarks` },
    { visible: true, alwaysVisibility: false, fieldName: `CollectionDate`, displayText: $localize`Payment Date` },
  ];
}
