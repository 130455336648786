import { Injectable } from '@angular/core';
import { AlertService } from '../alert/alert.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ParkingTransactionDetail } from 'src/app/components/parkingtransactionhistorylisting/parkingtransactionhistorylisting.component';
import { environment } from 'src/environments/environment';
import { QueryResult } from 'src/app/shared/models/queryResult';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { KeyValue } from '@angular/common';
import { CancelTransactionResponseModel } from 'src/app/shared/models/cancelTransactionResponseModel';
import { ParkingTransaction } from 'src/app/shared/models/parkingTransaction';
import { EnumDropdown } from 'src/app/shared/models/enumDropdown';

@Injectable({
  providedIn: 'root'
})
export class ParkingTransactionService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor(private http: HttpClient, private alertService: AlertService) { 
  }

  public getAll(keyword: string,
    parkingProvider: string,
    parkingDateStart: string,
    parkingDateEnd: string,
    paymentMeans: string,
    transactionStatus: string,
    modifiedDateStart: string,
    modifiedDateEnd: string,
    customerReferenceId: string,
    pageSize: number, pageNumber: number): Observable<QueryResult<ParkingTransaction>>
  {
    let baseEndpoint = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/transactions`;
    let queryString:[string,string][] = [ 
          ["keyword", keyword],
          ["parkingProvider", parkingProvider],
          ["parkingDateStart", parkingDateStart],
          ["parkingDateEnd", parkingDateEnd],
          ["paymentMeans", paymentMeans ],
          ["transactionStatus", transactionStatus],
          ["modifiedDateStart", modifiedDateStart],
          ["modifiedDateEnd", modifiedDateEnd],
          ["customerReferenceId", customerReferenceId],
          ["pageSize", `${pageSize}`],
          ["pageNumber",`${pageNumber}`]
    ];

    let url = `${baseEndpoint}?${queryString.map(n=> n.join(`=`)).join(`&`)}`;
      return this.http.get<QueryResult<ParkingTransaction>>(url, { headers: this.headers })
        .pipe(map(a => {
          return a;
        }));
  }

  public cancelTransaction(parkingTransactionReferenceID: string, comment: string): Observable<CancelTransactionResponseModel>
  {
    let baseEndpoint = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/transactions`;
   
    let transactions= {parkingTransactionReferenceId: parkingTransactionReferenceID, comment: comment};
    
    return this.http.request<CancelTransactionResponseModel[]>("delete", baseEndpoint, { headers: this.headers, body: transactions })
      .pipe(map(a=>{
        return a[0];
      }));
  }

  public getParkingTransaction(parkingTransactionReferenceID: string): Observable<ParkingTransactionDetail>
  {
    let baseEndpoint = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/transaction`;
    let queryString:[string,string][] = [
          ["parkingTransactionId", parkingTransactionReferenceID],
    ];

    let url = `${baseEndpoint}?${queryString.map(n=> n.join(`=`)).join(`&`)}`;
      return this.http.get<ParkingTransactionDetail>(url, { headers: this.headers })
        .pipe(map(a => {
          return a;
        }));
  }

  public withdrawTransaction(parkingTransactionReferenceID: string, comment: string): Observable<CancelTransactionResponseModel>
  {
    let baseEndpoint = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/transactions`;
    let transactions: Array<{parkingTransactionReferenceId: string, comment: string}> = [   {parkingTransactionReferenceId: parkingTransactionReferenceID, comment: comment}];

    return this.http.request<CancelTransactionResponseModel[]>("delete", baseEndpoint, { headers: this.headers, body: transactions })
      .pipe(map(a=>{
        return a[0];
      }));
  }

  public getParkingTransactionStatus(): Observable<QueryResult<EnumDropdown>>
  {
      let baseEndpoint = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/transaction/statusEnum`;

      let url = `${baseEndpoint}`;
        return this.http.get<QueryResult<EnumDropdown>>(url, { headers: this.headers })
          .pipe(map(a => {
            return a;
          }));
  }

  public getPaymentMeans(): Observable<QueryResult<EnumDropdown>>
  {
      let baseEndpoint = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/transaction/paymentMeans`;

      let url = `${baseEndpoint}`;
        return this.http.get<QueryResult<EnumDropdown>>(url, { headers: this.headers })
          .pipe(map(a => {
            return a;
          }));
  }
}
