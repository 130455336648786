<app-columnselector #columnSelector [columns]="defaultColumns"></app-columnselector>

<form [formGroup]="filterForm" (ngSubmit)="fetchData(paginator.pageSize, 1)" class="form-root">
  <mat-toolbar class="content-formfield-margin">
    <mat-form-field class="search-form-field">
      <span matPrefix>
        <mat-icon>search</mat-icon>
      </span>
      <input
        matInput
        i18n-placeholder
        placeholder="Filter by keyword"
        formControlName="filterKeyword"
        type="text"
        [(ngModel)]="keywordText"
        (keyup)="keywordChanged($event)"
      />
    </mat-form-field>
    <mat-form-field id="statusFilterFieldHolder">
      <mat-label i18n>Status</mat-label>
      <mat-select
        matNativeControl
        formControlName="status"
        [(ngModel)]="status"
        (selectionChange)="handleFilterFormChange()"
      >
        <mat-option *ngFor="let item of collectivePaymentStatusEnum" [value]="item.value">
          {{ item.text }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field id="triggerFilterFieldHolder">
      <mat-label i18n>Trigger Type</mat-label>
      <mat-select
        matNativeControl
        formControlName="triggerType"
        [(ngModel)]="trigger"
        (selectionChange)="handleFilterFormChange()"
      >
        <mat-option *ngFor="let item of collectivePaymentTriggerTypeEnum" [value]="item.value">
          {{ item.text }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="clearable">
      <mat-form-field id="date-filter-field-holder">
        <mat-label i18n="Payment Date">Payment Date</mat-label>
        <mat-date-range-input [rangePicker]="date" formGroupName="date">
          <input matStartDate formControlName="start" />
          <input matEndDate (dateChange)="handleFilterFormChange()" formControlName="end" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
        <mat-date-range-picker #date> </mat-date-range-picker>
      </mat-form-field>
      <mat-icon
        *ngIf="filterForm.value.date.start && filterForm.value.date.end"
        class="clear-icon"
        (click)="clearDate('date')"
        >close</mat-icon
      >
    </div>
  </mat-toolbar>
</form>
<mat-divider></mat-divider>
<div class="pagedtable-container">
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner></mat-spinner>
  </div>

  <mat-sidenav-content>
    <table mat-table [dataSource]="collectivePaymentDataSource">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef width="50px"></th>
        <td mat-cell *matCellDef="let row"></td>
      </ng-container>
      <!-- Position Column -->
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef><span i18n>position</span></th>
        <td mat-cell *matCellDef="let element">{{ element.position }}</td>
      </ng-container>
      <ng-container matColumnDef="BatchReferenceId">
        <th mat-header-cell *matHeaderCellDef width="10%"><span i18n>Batch Reference ID</span></th>
        <td mat-cell *matCellDef="let element">
          <a (click)="viewCollectivePaymentTransaction(element.id)" class="batch-collection-reference"
            >{{ element.batchCollectionReferenceID }}
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="Trigger">
        <th mat-header-cell *matHeaderCellDef width="10%"><span i18n>Trigger</span></th>
        <td mat-cell *matCellDef="let element">{{ element.triggerText }}</td>
      </ng-container>
      <ng-container matColumnDef="MeansOfPayment">
        <th mat-header-cell *matHeaderCellDef width="10%"><span i18n>Means Of Payment</span></th>
        <td mat-cell *matCellDef="let element">{{ element.meansOfPayment }}</td>
      </ng-container>
      <ng-container matColumnDef="TotalAmount">
        <th mat-header-cell *matHeaderCellDef width="10%"><span i18n>Total Amount</span></th>
        <td mat-cell *matCellDef="let element">{{ element.totalAmount | currency: '' : '' }}</td>
      </ng-container>
      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef><span i18n>Status</span></th>
        <td mat-cell *matCellDef="let element">{{ element.statusText }}</td>
      </ng-container>
      <ng-container matColumnDef="SaferPayRemarks">
        <th mat-header-cell *matHeaderCellDef><span i18n>Remarks</span></th>
        <td mat-cell *matCellDef="let element">{{ element.saferPayRemarks }}</td>
      </ng-container>
      <ng-container matColumnDef="CollectionDate">
        <th mat-header-cell *matHeaderCellDef width="10%"><span i18n>Payment Date</span></th>
        <td mat-cell *matCellDef="let element">{{ element.collectionDateText }}</td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="columnSelector == undefined ? [] : columnSelector.displayedColumns; sticky: true"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columnSelector == undefined ? [] : columnSelector.displayedColumns"
      ></tr>
    </table>
  </mat-sidenav-content>
</div>
<mat-paginator
  [pageSize]="defaultSettings.table.pageSize"
  [pageSizeOptions]="defaultSettings.table.pageOptions"
  (page)="handlePaginationChange()"
  showFirstLastButtons
></mat-paginator>
