<app-columnselector #columnSelector [columns]="defaultColumns"></app-columnselector>

<form [formGroup]="filterForm" (ngSubmit)="fetchData(paginator.pageSize, 1)" class="form-root">
  <mat-toolbar class="content-formfield-margin search-toolbar">
    <mat-form-field class="search-form-field">
      <span matPrefix><mat-icon>search</mat-icon></span>
      <input
        matInput
        i18n-placeholder
        placeholder="Filter by keyword"
        formControlName="filterKeyword"
        type="text"
        [(ngModel)]="keywordText"
        (keyup)="keywordChanged($event)"
      />
    </mat-form-field>
    <mat-form-field id="actionFilterFieldHolder">
      <mat-label i18n>Action</mat-label>
      <mat-select
        matNativeControl
        formControlName="action"
        [(ngModel)]="action"
        (selectionChange)="handleFilterFormChange()"
      >
        <mat-option *ngFor="let item of creditNoteActionEnum" [value]="item.value">
          {{ item.text }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="clearable">
      <mat-form-field id="date-filter-field-holder">
        <mat-label i18n="Date">Date</mat-label>
        <mat-date-range-input [rangePicker]="date" formGroupName="date">
          <input matStartDate formControlName="start" />
          <input matEndDate (dateChange)="handleFilterFormChange()" formControlName="end" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="date" />
        <mat-date-range-picker #date> </mat-date-range-picker>
      </mat-form-field>
      <mat-icon
        *ngIf="filterForm.value.date.start && filterForm.value.date.end"
        class="clear-icon"
        (click)="clearDate('date')"
        >close</mat-icon
      >
    </div>
  </mat-toolbar>
</form>
<mat-divider></mat-divider>
<div class="pagedtable-container">
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner></mat-spinner>
  </div>

  <mat-sidenav-content>
    <table mat-table [dataSource]="dataSource" class="content-table">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef width="50px"></th>
        <td mat-cell *matCellDef="let row"></td>
      </ng-container>
      <!-- Position Column -->
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef><span i18n>position</span></th>
        <td mat-cell *matCellDef="let element">{{ element.position }}</td>
      </ng-container>
      <ng-container matColumnDef="ID">
        <th mat-header-cell *matHeaderCellDef width="10%"><span i18n>Reference ID</span></th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>
      <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef width="10%"><span i18n>Action</span></th>
        <td mat-cell *matCellDef="let element">{{ element.actionText }}</td>
      </ng-container>
      <ng-container matColumnDef="Amount">
        <th mat-header-cell *matHeaderCellDef width="10%"><span i18n>Amount</span></th>
        <td mat-cell *matCellDef="let element">{{ element.amount | currency: '' : '' }}</td>
      </ng-container>
      <ng-container matColumnDef="Reason">
        <th mat-header-cell *matHeaderCellDef><span i18n>Reason</span></th>
        <td mat-cell *matCellDef="let element">{{ element.reason }}</td>
      </ng-container>
      <ng-container matColumnDef="GiverName">
        <th mat-header-cell *matHeaderCellDef><span i18n>Modified By</span></th>
        <td mat-cell *matCellDef="let element">{{ element.giverName }}</td>
      </ng-container>
      <ng-container matColumnDef="Date">
        <th mat-header-cell *matHeaderCellDef width="10%"><span i18n>Date</span></th>
        <td mat-cell *matCellDef="let element">{{ element.dateText }}</td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="columnSelector == undefined ? [] : columnSelector.displayedColumns; sticky: true"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columnSelector == undefined ? [] : columnSelector.displayedColumns"
      ></tr>
    </table>
  </mat-sidenav-content>
</div>
<mat-paginator
  [pageSize]="defaultSettings.table.pageSize"
  [pageSizeOptions]="defaultSettings.table.pageOptions"
  (page)="handlePaginationChange()"
  showFirstLastButtons
></mat-paginator>
