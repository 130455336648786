<span class="cdk-visually-hidden" #tooltipAddCredit i18n>Add Credit Note</span>
<span class="cdk-visually-hidden" #tooltipClearFilter i18n>Clear All Filters</span>

<mat-toolbar>
  <mat-toolbar-row>
    <div class="breadcrumb-container">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a i18n-href [routerLink]="['/customers']" i18n>Customers</a></li>
          <li class="breadcrumb-item" aria-current="page">{{ customer.firstName }} {{ customer.lastName }}</li>
          <li class="breadcrumb-item">{{ selectedTabLabel() }}</li>
        </ol>
      </nav>
    </div>
    <div class="content-button-margin">
      <button mat-raised-button color="primary" *ngIf="customer.isBlocked == false" (click)="openBlockDialog()">
        <mat-icon>lock</mat-icon> <span i18n>Block Customer</span>
      </button>
      <button mat-raised-button color="primary" *ngIf="customer.isBlocked == true" (click)="openBlockDialog()">
        <mat-icon>lock_open</mat-icon> <span i18n>Unblock Customer</span>
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="AddCreditNote()"
        [matTooltip]="tooltipAddCredit.innerHTML"
        matTooltipPosition="above"
      >
        <mat-icon>credit_card</mat-icon> <span i18n>Credit Note</span>
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="ClearFilters()"
        [matTooltip]="tooltipClearFilter.innerHTML"
        matTooltipPosition="above"
      >
        <mat-icon>clear_all</mat-icon><span i18n>Clear All Filters</span>
      </button>
      <button mat-raised-button (click)="ShowColumnSelector()">
        <mat-icon>view_column</mat-icon><span i18n>Columns</span>
      </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<mat-card>
  <mat-card-content>
    <mat-grid-list cols="4" rowHeight="80px">
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div>
          <mat-form-field appearance="none">
            <mat-label i18n>Customer Reference ID</mat-label>
            <input readonly="true" matInput value="{{ customer.customerReferenceID }}" />
          </mat-form-field>
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div>
          <mat-form-field appearance="none">
            <mat-label i18n>Name</mat-label>
            <input readonly="true" matInput value="{{ customer.firstName }} {{ customer.lastName }}" />
          </mat-form-field>
        </div>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div>
          <mat-form-field appearance="none">
            <mat-label i18n>Status</mat-label>
            <input readonly="true" matInput value="{{ status }}" />
          </mat-form-field>
        </div>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div>
          <mat-form-field appearance="none" class="highlight">
            <mat-label i18n>Total Payable Amount</mat-label>
            <input readonly="true" matInput value="{{ customer.totalPayable | currency: '' : '' }} CHF" />
          </mat-form-field>
        </div>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div>
          <mat-form-field appearance="none">
            <mat-label i18n>TCS Member ID</mat-label>
            <input readonly="true" matInput value="{{ customer.tcsMemberNumber }}" />
          </mat-form-field>
        </div>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div>
          <mat-form-field appearance="none">
            <mat-label i18n>E-Mail</mat-label>
            <input readonly="true" matInput value="{{ customer.emailAddress }}" />
          </mat-form-field>
        </div>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div></div>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div>
          <mat-form-field appearance="none" class="highlight">
            <mat-label i18n>Available Credit Note</mat-label>
            <input readonly="true" matInput value="{{ customer.totalCreditNote | currency: '' : '' }} CHF" />
          </mat-form-field>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>

<mat-tab-group #customerTabs>
  <mat-tab i18n-label label="Parking">
    <app-parkingtransactionlisting
      [customerReferenceId]="customerReferenceId"
      (onCancelSuccess)="CancelTransactionSuccess()"
    ></app-parkingtransactionlisting>
  </mat-tab>
  <mat-tab i18n-label label="Credit Notes">
    <app-creditnotelisting [customerReferenceId]="customerReferenceId"></app-creditnotelisting>
  </mat-tab>
  <mat-tab i18n-label label="Payment">
    <app-collectivepaymentlisting [customerReferenceId]="customerReferenceId"></app-collectivepaymentlisting>
  </mat-tab>
  <mat-tab label="{{ blockingHistory }}">
    <app-blockinghistory [customerReferenceId]="customerReferenceId"></app-blockinghistory>
  </mat-tab>
</mat-tab-group>
