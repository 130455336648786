<h4 mat-dialog-title>
  <mat-icon class="dialog-icon">credit_card</mat-icon><span class="span-title" i18n>Add Credit Note Promotion</span>
</h4>
<form [formGroup]="addCreditNotePromotionForm" *ngIf="pageLoaded">
  <div mat-dialog-content>
    <mat-form-field style="width: 100%">
      <mat-label><span i18n>Promotion Name</span></mat-label>
      <input matInput formControlName="name" required />
      <mat-error *ngIf="f.name.invalid">
        <span *ngIf="f.name.hasError('required')" i18n>You must enter a value</span>
        <span *ngIf="f.name.hasError('maxlength')" i18n>Max length for reason is 250 characters</span>
      </mat-error>
    </mat-form-field>
    <mat-form-field style="width: 100%; margin-top: 1em">
      <mat-label><span i18n>Promotion Code</span></mat-label>
      <input matInput formControlName="code" required [maxlength]="stringMaxLength" />
      <mat-error *ngIf="f.code.invalid">
        <span *ngIf="f.code.hasError('pattern')" i18n>Promo Code can only have letters and numbers</span>
        <span *ngIf="f.code.hasError('required')" i18n>You must enter a value</span>
        <span *ngIf="f.code.hasError('maxlength')" i18n>Max length for reason is 250 characters</span>
      </mat-error>
    </mat-form-field>
    <mat-form-field style="width: 100%; margin-top: 1em">
      <mat-label><span i18n>Credit Note Amount in CHF</span></mat-label>
      <input matInput formControlName="amount" required />
      <mat-error *ngIf="f.amount.invalid">
        <span *ngIf="f.amount.hasError('pattern')" i18n
          >Amount should only have numeric value that supports two decimal places.</span
        >
        <span *ngIf="f.amount.hasError('required')" i18n>You must enter a value</span>
      </mat-error>
    </mat-form-field>
    <mat-form-field style="width: 100%; margin-top: 1em">
      <mat-label><span i18n>Maximum number of redemptions</span></mat-label>
      <input matInput formControlName="maxRedemption" required type="number" />
      <mat-error *ngIf="f.maxRedemption.invalid">
        <span *ngIf="f.maxRedemption.hasError('required')" i18n>You must enter a value</span>
      </mat-error>
    </mat-form-field>

      <mat-form-field class="duration-start">
        <input
          matInput
          [matDatepicker]="durationStart"
          formControlName="durationStart"
          [placeholder]="formControlPlaceholders.startDate"
        />
        <mat-datepicker-toggle matSuffix [for]="durationStart"></mat-datepicker-toggle>
        <mat-datepicker #durationStart></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          [matDatepicker]="durationEnd"
          formControlName="durationEnd"
          [placeholder]="formControlPlaceholders.endDate"
        />
        <mat-datepicker-toggle matSuffix [for]="durationEnd"></mat-datepicker-toggle>
        <mat-datepicker #durationEnd></mat-datepicker>
      </mat-form-field>

    <mat-form-field>
      <input
        matInput
        [matDatepicker]="expiration"
        formControlName="expiration"
        [placeholder]="formControlPlaceholders.expiration"
      />
      <mat-datepicker-toggle matSuffix [for]="expiration"></mat-datepicker-toggle>
      <mat-datepicker #expiration></mat-datepicker>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button
      mat-button
      [disabled]="
        f.amount.invalid ||
        f.name.invalid ||
        f.code.invalid ||
        f.maxRedemption.invalid ||
        f.durationStart.invalid ||
        f.durationEnd.invalid ||
        f.expiration.invalid
      "
      (click)="onConfirmClick()"
      i18n
    >
      Confirm
    </button>
    <button mat-button (click)="onCancelClick()" i18n>Cancel</button>
  </div>
</form>
