import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { CustomerService } from 'src/app/core/services/customer.service';
import { Moment } from 'moment';
import { PageMetaData } from 'src/app/shared/models/queryResult';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CustomerPageItem } from 'src/app/shared/models/customerPageItem';
import { TableColumn } from 'src/app/shared/models/tableColumn';
import { ColumnSelectorComponent } from 'src/app/shared/components/columnSelector/columnselector.component';
import { collectivePaymentService } from 'src/app/core/services/collectivePayment.service';
import { EnumDropdown } from 'src/app/shared/models/enumDropdown';
import { Sort } from '@angular/material/sort';
import { SortDirection } from 'src/app/shared/enums/sortDirectionEnum';
import { CustomerListingFilterI } from 'src/app/shared/models/customerListingFilter';

@Component({
  selector: 'app-customerlisting',
  templateUrl: './customerlisting.component.html',
  styleUrls: ['./customerlisting.component.scss'],
})
export class CustomerlistingComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(ColumnSelectorComponent) columnSelector: ColumnSelectorComponent;

  public dataSource: CustomerPageItem[] = [];

  public defaultSettings: any;
  public collectivePaymentStatusEnum: EnumDropdown[] = [];

  public keywordText: string;
  public isLoadingResults = false;

  public filterForm: FormGroup<CustomerListingFilterI>;

  public lastCollectionStatus: string;
  public oldKeywordValue: string;

  private _dataSourceMetaData: PageMetaData;

  private _sort: string = 'LastParkingDate';
  private _sortDirection: SortDirection = SortDirection.Descending;

  private _customerService: CustomerService;
  private _collectivePaymentService: collectivePaymentService;

  constructor(
    customerService: CustomerService,
    collectivePaymentService: collectivePaymentService,
    filterFormBuilder: FormBuilder
  ) {
    this.defaultSettings = environment.defaultSettings;
    this._customerService = customerService;
    this._collectivePaymentService = collectivePaymentService;

    this.buildFormGroup(filterFormBuilder);
  }

  ngOnInit() {
    this.fetchData(environment.defaultSettings.table.pageSize, 1);
    this.fetchCollectivePaymentStatus();
  }

  private buildFormGroup(filterFormBuilder: FormBuilder) {
    this.filterForm = filterFormBuilder.group({
      filterKeyword: new FormControl(''),
      lastParkingDate: filterFormBuilder.group({
        start: new FormControl(''),
        end: new FormControl(''),
      }),
      appRegistrationDate: filterFormBuilder.group({
        start: new FormControl(''),
        end: new FormControl(''),
      }),
      lastCollectionStatus: new FormControl(''),
      lastCollectionDate: filterFormBuilder.group({
        start: new FormControl(''),
        end: new FormControl(''),
      }),
    });
  }

  private fetchCollectivePaymentStatus() {
    this._collectivePaymentService.getCollectivePaymentStatus().subscribe((result) => {
      this.collectivePaymentStatusEnum = result.items;
    });
  }

  public handleFilterFormChange() {
    this.fetchData(this.paginator.pageSize, 1);
  }

  public handlePaginationChange() {
    this.fetchData(this.paginator.pageSize, this.paginator.pageIndex + 1);
  }

  public clearFilters(): void {
    this.filterForm.get('filterKeyword').setValue('');
    this.clearDate('lastParkingDate');
    this.clearDate('appRegistrationDate');
    this.filterForm.get('lastCollectionStatus').setValue('');
    this.clearDate('lastCollectionDate');

    this.handleFilterFormChange();
  }

  public clearDate(key: string) {
    this.filterForm.get(key).get('start').setValue('');
    this.filterForm.get(key).get('end').setValue('');
    this.handleFilterFormChange();
  }

  private filterChangedTimeout;

  public keywordChanged(event: KeyboardEvent) {
    if (this.oldKeywordValue == this.keywordText) {
      return;
    }

    this.oldKeywordValue = this.keywordText;

    if (this.filterChangedTimeout) {
      clearTimeout(this.filterChangedTimeout);
    }

    if (event.keyCode == 13) {
      this.fetchData(this.paginator.pageSize, 1);
      return;
    }

    this.filterChangedTimeout = setTimeout(() => {
      this.fetchData(this.paginator.pageSize, 1);
    }, 1000);
  }

  private fetchDataProcessId: number = 1;

  public fetchData(pageSize: number, pageNumber: number) {
    const lastParkingDateStart = this.formatDate(this.filterForm.value.lastParkingDate.start);
    const lastParkingDateEnd = this.formatDate(this.filterForm.value.lastParkingDate.end);

    const appRegistrationDateStart = this.formatDate(this.filterForm.value.appRegistrationDate.start);
    const appRegistrationDateEnd = this.formatDate(this.filterForm.value.appRegistrationDate.end);

    const lastCollectionDateStart = this.formatDate(this.filterForm.value.lastCollectionDate.start);
    const lastCollectionDateEnd = this.formatDate(this.filterForm.value.lastCollectionDate.end);

    let fetchDataProcessId = this.fetchDataProcessId + 1;
    this.isLoadingResults = true;
    this.fetchDataProcessId = fetchDataProcessId;

    let lastStatus = this.lastCollectionStatus;
    if (lastStatus == 'ALL') {
      lastStatus = null;
    }
    let sub = this._customerService.getAll(
      this.keywordText,
      lastParkingDateStart,
      lastParkingDateEnd,
      lastStatus,
      lastCollectionDateStart,
      lastCollectionDateEnd,
      appRegistrationDateStart,
      appRegistrationDateEnd,
      pageSize,
      pageNumber,
      this._sort,
      this._sortDirection
    );
    sub.subscribe((result) => {
      if (this.fetchDataProcessId != fetchDataProcessId) {
        return; //skip when the processid is old
      }
      this.isLoadingResults = false;
      this.dataSource = result.items;

      this._dataSourceMetaData = result.pageMetaData;

      this.paginator.length = result.pageMetaData.totalItemCount;
      this.paginator.pageIndex = result.pageMetaData.pageNumber - 1;
    });
  }

  public sortData(sort: Sort) {
    this._sort = sort.active;
    this._sortDirection = sort.direction == 'asc' ? SortDirection.Ascending : SortDirection.Descending;
    this.fetchData(this.paginator.pageSize, 1);
  }

  /**
   * Type guard for distinguishing between `string` and `Moment` types.
   *
   * @param value the value to check
   * @returns boolean indicating whether the variable is a Moment
   */
  private isMoment(value: string | Moment): value is Moment {
    return typeof value !== 'string';
  }

  private formatDate(date: string | Moment) {
    if (!this.isMoment(date)) {
      return date;
    }
    return date.format(environment.defaultSettings.dateMomentFormat);
  }

  public defaultColumns: TableColumn[] = [
    {
      visible: true,
      alwaysVisibility: true,
      fieldName: `CustomerReferenceID`,
      displayText: $localize`Customer Reference ID`,
    },
    { visible: true, alwaysVisibility: false, fieldName: `TCSMemberID`, displayText: $localize`TCS Member ID` },
    { visible: true, alwaysVisibility: false, fieldName: `FirstName`, displayText: $localize`First Name` },
    { visible: true, alwaysVisibility: false, fieldName: `LastName`, displayText: $localize`Last Name` },
    { visible: true, alwaysVisibility: false, fieldName: `LastUsedCar`, displayText: $localize`Last license plate` },
    { visible: true, alwaysVisibility: false, fieldName: `LastParkingDate`, displayText: $localize`Last Parking Date` },
    { visible: true, alwaysVisibility: false, fieldName: `EmailAddress`, displayText: $localize`Email Address` },
    {
      visible: true,
      alwaysVisibility: false,
      fieldName: `ParkingAppRegistrationDate`,
      displayText: $localize`P&P Registration Date`,
    },
    {
      visible: true,
      alwaysVisibility: false,
      fieldName: `TotalUncollectedAmount`,
      displayText: $localize`Open Amount`,
    },
    {
      visible: true,
      alwaysVisibility: false,
      fieldName: `LastCollectionStatus`,
      displayText: $localize`Payment Status`,
    },
    { visible: true, alwaysVisibility: false, fieldName: `LastCollectionDate`, displayText: $localize`Payment Date` },
    {
      visible: true,
      alwaysVisibility: false,
      fieldName: `TotalTransactionAmountForTheLast365Days`,
      displayText: $localize`Total Transaction Amount for the Last 365 Days`,
    },
    { visible: true, alwaysVisibility: false, fieldName: `IsBlocked`, displayText: $localize`Status` },
  ];
}
