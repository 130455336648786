export class CreditNotePromotionPageItem {
    promoId: string;
    creditNoteID: string;
    name: string;
    code: string;
    amount: number;
    cumulatedAmountUsed: number;
    maxRedemptions: number;
    currentNumberOfRedemptions: number;
    maxCumulatedAmountPossible: number;
    creationDate: string;
    durationStart: string;
    durationEnd: string;
    expiration: string;
    status: string;
    withdrawn: boolean;
  }