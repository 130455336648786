import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ColumnSelectorComponent } from 'src/app/shared/components/columnSelector/columnselector.component';
import { TableColumn } from 'src/app/shared/models/tableColumn';
import { CreditNotePageItem } from 'src/app/shared/models/creditNotePageItem';
import { PageMetaData } from 'src/app/shared/models/queryResult';
import { creditNoteService } from 'src/app/core/services/creditNote.service';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDatepicker } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { EnumDropdown } from 'src/app/shared/models/enumDropdown';
import { CreditNoteListingFilterI } from 'src/app/shared/models/creditNoteListingFilter';

@Component({
  selector: 'app-creditnotelisting',
  templateUrl: './creditnotelisting.component.html',
  styleUrls: ['./creditnotelisting.component.scss'],
})
export class CreditNoteListingComponent implements OnInit {
  dataSource: CreditNotePageItem[] = [];
  private dataSoureMetaData: PageMetaData;
  private selection = new SelectionModel<CreditNotePageItem>(true, []);
  creditNoteActionEnum: EnumDropdown[] = [];

  private fetchDataProcessId = 1;
  keywordText: string;
  isLoadingResults = false;
  private filterChangedTimeout;
  public filterForm: FormGroup<CreditNoteListingFilterI>;
  public oldKeywordValue: string;

  defaultSettings: any;
  action: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(ColumnSelectorComponent) columnSelector: ColumnSelectorComponent;
  @ViewChild('date') date: MatDatepicker<Moment>;

  @Input()
  customerReferenceId: string;

  constructor(
    private creditNoteService: creditNoteService,
    filterFormBuilder: FormBuilder
  ) {
    this.defaultSettings = environment.defaultSettings;

    this.buildFormGroup(filterFormBuilder);
  }

  ngOnInit() {
    this.fetchData(environment.defaultSettings.table.pageSize, 1);
    this.fetchCreditNoteStatusEnum();
  }

  private buildFormGroup(filterFormBuilder: FormBuilder) {
    this.filterForm = filterFormBuilder.group({
      filterKeyword: new FormControl(''),
      action: new FormControl(''),
      date: filterFormBuilder.group({
        start: new FormControl(''),
        end: new FormControl(''),
      }),
    });
  }

  public handleFilterFormChange() {
    this.fetchData(this.paginator.pageSize, 1);
  }

  public handlePaginationChange() {
    this.fetchData(this.paginator.pageSize, this.paginator.pageIndex + 1);
  }

  public clearFilters() {
    this.filterForm.get('filterKeyword').setValue('');
    this.filterForm.get('action').setValue('');
    this.clearDate('date');

    this.handleFilterFormChange();
  }

  public clearDate(key: string) {
    this.filterForm.get(key).get('start').setValue('');
    this.filterForm.get(key).get('end').setValue('');
    this.handleFilterFormChange();
  }

  reloadPage() {
    this.fetchData(this.paginator.pageSize, this.paginator.pageIndex + 1);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.forEach((row) => this.selection.select(row));
  }

  keywordChanged(event: KeyboardEvent) {
    if (this.oldKeywordValue == this.keywordText) {
      return;
    }

    this.oldKeywordValue = this.keywordText;

    if (this.filterChangedTimeout) {
      clearTimeout(this.filterChangedTimeout);
    }

    if (event.keyCode === 13) {
      this.fetchData(this.paginator.pageSize, 1);
      return;
    }

    this.filterChangedTimeout = setTimeout(() => {
      this.fetchData(this.paginator.pageSize, 1);
    }, 1000);
  }

  fetchData(pageSize: number, pageNumber: number) {
    const fetchDataProcessId = this.fetchDataProcessId + 1;
    this.fetchDataProcessId = fetchDataProcessId;

    const dateStart = this.formatDate(this.filterForm.value.date.start);
    const dateEnd = this.formatDate(this.filterForm.value.date.end);

    let keywordText = this.filterForm.get('filterKeyword').value;
    let action = this.filterForm.get('action').value;

    if (action == 'ALL') {
      action = null;
    }

    this.isLoadingResults = true;
    this.creditNoteService
      .getAllCreditNote(this.customerReferenceId, keywordText, dateStart, dateEnd, action, pageSize, pageNumber)
      .subscribe((result) => {
        if (this.fetchDataProcessId != fetchDataProcessId) {
          return; //skip when the processid is old
        }

        this.isLoadingResults = false;
        this.dataSource = result.items;
        this.dataSoureMetaData = result.pageMetaData;

        this.paginator.length = result.pageMetaData.totalItemCount;
        this.paginator.pageIndex = result.pageMetaData.pageNumber - 1;
      });
  }

  /**
   * Type guard for distinguishing between `string` and `Moment` types.
   *
   * @param value the value to check
   * @returns boolean indicating whether the variable is a Moment
   */
  private isMoment(value: string | Moment): value is Moment {
    return typeof value !== 'string';
  }

  private formatDate(date: string | Moment) {
    if (!this.isMoment(date)) {
      return date;
    }
    return date.format(environment.defaultSettings.dateMomentFormat);
  }

  fetchCreditNoteStatusEnum() {
    this.creditNoteService.getCreditNoteAction().subscribe((result) => {
      this.creditNoteActionEnum = result.items;
    });
  }

  public defaultColumns: TableColumn[] = [
    { visible: true, alwaysVisibility: true, fieldName: `ID`, displayText: $localize`ID` },
    { visible: true, alwaysVisibility: false, fieldName: `Action`, displayText: $localize`Action` },
    { visible: true, alwaysVisibility: false, fieldName: `Amount`, displayText: $localize`Amount` },
    { visible: true, alwaysVisibility: false, fieldName: `Reason`, displayText: $localize`Reason` },
    { visible: true, alwaysVisibility: false, fieldName: `GiverName`, displayText: $localize`GiverName` },
    { visible: true, alwaysVisibility: false, fieldName: `Date`, displayText: $localize`Date` },
  ];
}
