import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { QueryResult } from 'src/app/shared/models/queryResult';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CustomerPageItem } from 'src/app/shared/models/customerPageItem';
import { CustomerInformation } from 'src/app/shared/models/customerInformation';
import { SortDirection } from 'src/app/shared/enums/sortDirectionEnum';
import { UpdateStatus } from 'src/app/shared/models/updateStatus';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor(private http: HttpClient) { 
  }

  public getAll(keyword: string, lastTransactionStart: string, lastTransactionEnd: string, lastCollectivePaymentStatus: string, lastCollectivePaymentStart: string, lastCollectivePaymentEnd: string, appRegistrationStart: string, appRegistrationEnd: string, pageSize: number, pageNumber: number, sort: string = "", sortDirection: SortDirection = SortDirection.None): Observable<QueryResult<CustomerPageItem>>
  {
    const baseEndpoint = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/customers`;
    const queryString:[string,string][] = [ 
           ["keyword", keyword ],
           ["lastTransactionStart", lastTransactionStart],
           ["lastTransactionEnd", lastTransactionEnd],
           ["lastCollectivePaymentStatus", lastCollectivePaymentStatus],
           ["lastCollectivePaymentStart", lastCollectivePaymentStart],
           ["lastCollectivePaymentEnd", lastCollectivePaymentEnd],
           ["appRegistrationStart", appRegistrationStart],
           ["appRegistrationEnd", appRegistrationEnd],
           ["pageSize", `${pageSize}`],
           ["pageNumber",`${pageNumber}`],
           ["sort",`${sort}`],
           ["sortDirection",`${sortDirection}`]
    ];
    const url = `${baseEndpoint}?${queryString.map(n=> n.join(`=`)).join(`&`)}`;
    return this.http.get<QueryResult<CustomerPageItem>>(url, { headers: this.headers });
  }

  public getCustomer(customerId: string ): Observable<CustomerInformation>
  {
    const baseEndpoint = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/customers/${customerId}`;
    
    const url = `${baseEndpoint}`;
      return this.http.get<CustomerInformation>(url, { headers: this.headers })
        .pipe(map(a => {
          return a;
        }));
  }

  public updateStatus(param: UpdateStatus, customerReferenceId: string)
  {
    const baseEndpoint = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/customers/${customerReferenceId}/updateStatus`;
    
    const url = `${baseEndpoint}`;
    return this.http.put<any>(url, param, { headers: this.headers }).toPromise();
  }

  public blockingHistory(customerReferenceId: string)
  {
    const baseEndpoint = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/customers/${customerReferenceId}/blockinghistory`;
    
    const url = `${baseEndpoint}`;
    return this.http.get<any>(url, { headers: this.headers }).toPromise();
  }
}