import { Injectable } from '@angular/core';
import { AlertService } from '../alert/alert.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { QueryResult } from 'src/app/shared/models/queryResult';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CreditNotePromotionPageItem } from 'src/app/shared/models/creditnotePromotionPageItem';
import { SortDirection } from 'src/app/shared/enums/sortDirectionEnum';
import { CreditNotePromotionCustomerPageItem } from 'src/app/shared/models/creditnotepromotioncustomersPageItem';

@Injectable({
  providedIn: 'root'
})
export class CreditNotePromotionService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor(private http: HttpClient, private alertService: AlertService) {
  }

  public getAllCustomers(
    promotionId: string,
    pageSize: number,
    pageNumber: number,
    sort: string = "",
    sortDirection: SortDirection = SortDirection.None): Observable<QueryResult<CreditNotePromotionCustomerPageItem>> {
    let baseEndpoint = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/creditnotepromotions/${promotionId}/customers`;
    let queryString: [string, string][] = [
      ["pageSize", `${pageSize}`],
      ["pageNumber", `${pageNumber}`],
      ["sort", `${sort}`],
      ["sortDirection", `${sortDirection}`]
    ];

    let url = `${baseEndpoint}?${queryString.map(n => n.join(`=`)).join(`&`)}`;
    return this.http.get<QueryResult<CreditNotePromotionCustomerPageItem>>(url, { headers: this.headers })
      .pipe(map(a => {
        return a;
      }));
  }

  public getAllCustomersReport(
    promotionId: string,
    sort: string = "",
    sortDirection: SortDirection = SortDirection.None) {
    let baseEndpoint = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/creditnotepromotions/${promotionId}/customers/report`;

    let headers = this.headers;
    let url = `${baseEndpoint}`;
    return this.http.get(url, { headers, responseType: 'blob'}).toPromise();
  }


  public getAll(
    keyword: string,
    pageSize: number,
    pageNumber: number,
    sort: string = "",
    sortDirection: SortDirection = SortDirection.None): Observable<QueryResult<CreditNotePromotionPageItem>> {
    let baseEndpoint = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/creditnotepromotions`;
    let queryString: [string, string][] = [
      ["keyword", keyword],
      ["pageSize", `${pageSize}`],
      ["pageNumber", `${pageNumber}`],
      ["sort", `${sort}`],
      ["sortDirection", `${sortDirection}`]
    ];

    let url = `${baseEndpoint}?${queryString.map(n => n.join(`=`)).join(`&`)}`;
    return this.http.get<QueryResult<CreditNotePromotionPageItem>>(url, { headers: this.headers })
      .pipe(map(a => {
        return a;
      }));
  }

  public getPromotion(id: string): Observable<CreditNotePromotionPageItem> {
    let baseEndpoint = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/creditnotepromotions/${id}/`;

    let url = `${baseEndpoint}`;
    return this.http.get<CreditNotePromotionPageItem>(url, { headers: this.headers })
      .pipe(map(a => {
        return a;
      }));
  }

  public AddPromotion(promo: CreditNotePromotionPageItem) {
    let baseEndpoint = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/creditnotepromotions/`;

    return this.http.post(baseEndpoint, promo, { headers: this.headers });
  }

  public withdrawPromotion(id: string) {
    let baseEndpoint = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/creditnotepromotions/${id}`;

    let url = `${baseEndpoint}`;
    return this.http.delete(url, { headers: this.headers })
      .pipe(map(a => {
        return a;
      }));
  }
}