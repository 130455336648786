import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID  } from '@angular/core';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './components/login/login.component';
import { environment } from './../environments/environment';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './angular-material.module';
import { CustomerlistingComponent } from './components/customerlisting/customerlisting.component';
import { ParkingTransactionListingComponent } from './components/parkingtransactionlisting/parkingtransactionlisting.component'; 
import { CreditnotePromotionsComponent } from './components/creditnotepromotions/creditnotepromotions.component';
import { CreditnotePromotionsInfoComponent } from './components/creditnotepromotionsinfo/creditnotepromotionsinfo.component';
import { CreditnotePromotionsCustomersComponent } from './components/creditnotepromotionscustomers/creditnotepromotionscustomers.component';

import { ToastrModule } from 'ngx-toastr';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter'
import { GlobalExportComponent } from './components/global-export/global-export.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogServiceDialog } from './shared/components/dialogservicedialog/dialogservice.dialog.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { LocalizedMatPaginatorIntl } from './shared/localized-paginator';
import { AddCreditNoteDialog } from './shared/components/addcreditnotedialog/addcreditnote.dialog.compoment';
import { AddCreditNotePromotionDialog } from './shared/components/addCreditNotePromotionDialog/addcreditnotepromotion.dialog.component';
import { CustomerInformationComponent } from './components/customerinfo/customerinformation.component';
import { ParkingTransactionHistoryListingComponent } from './components/parkingtransactionhistorylisting/parkingtransactionhistorylisting.component';
import { ColumnSelectorComponent } from './shared/components/columnSelector/columnselector.component';
import { CreditNoteListingComponent } from './components/creditnotelisting/creditnotelisting.component';
import { AddCommentDialog } from './shared/components/addcommentdialog/addcomment.dialog.component';
import { CollectivepaymentlistingComponent } from './components/collectivepaymentlisting/collectivepaymentlisting.component';
import { CollectivePaymentTransactionDialog } from './shared/components/collectivepaymenttransactiondialog/collectivepaymenttransaction.dialog.component';
import { AddblockcommentdialogComponent } from './shared/components/addblockcommentdialog/addblockcommentdialog.component';
import { WithdrawPromotionDialogComponent } from './shared/components/withdrawpromotiondialog/withdrawpromotiondialog.component';
import { BlockinghistoryComponent } from './components/blockinghistory/blockinghistory.component';
import { ExportMarketingReportComponent } from './shared/components/export-marketing-report/export-marketing-report.component';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel, Configuration} from '@azure/msal-browser';
import {MatToolbarModule} from '@angular/material/toolbar'; 

import {CommonModule} from '@angular/common';
import {
    MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService,
    MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent, ProtectedResourceScopes
} from '@azure/msal-angular';
import { RoleGuard } from './role-guard';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
    auth: {
        clientId: environment.clientId, // This is the ONLY mandatory field that you need to supply.
        authority: environment.authority, // Defaults to "https://login.microsoftonline.com/common"
        redirectUri: ("redirectUri" in environment)? environment.redirectUri as string : undefined, // Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
        postLogoutRedirectUri: environment.postLogoutRedirectUri, // Points to window.location.origin by default.
        clientCapabilities: ['CP1'] // This lets the resource server know that this client can handle claim challenges.
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        //storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
    },
    system: {
        /**
         * Below you can configure MSAL.js logs. For more information, visit:
         * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
         */
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
                console.log(message);
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: true
        }
    }
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: []
};



/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
}

const getClaimsFromStorage = (claimsChallengeId: string): any => {
    return sessionStorage.getItem(claimsChallengeId);
};

/**
 * MSAL Angular will automatically retrieve tokens for resources
 * added to protectedResourceMap. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

    const scopesAll : string[] = [
        'user.read',
        'openid',
        'profile',
    ];
    
    const scopesWithoutGraph : string[] = [
       `api://${environment.clientId}/access_via_approle_assignments`,
    ];
    
    // What resources are protected and which scopes need to be requested to access them.
    // In local deployment, no resources are protected, effectively removing the need for MSAL and having a login.
    if (!environment.isLocal) {
        protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', scopesAll);     // this is required so it can get the user's first name for the "Hello XYZ!" display in the hamburger menu
        protectedResourceMap.set(environment.backendApiUrl + '/*', scopesWithoutGraph); // all backend API calls
    }

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
        authRequest: (msalService, httpReq, originalAuthRequest) => {
            const resource = new URL(httpReq.url).hostname;
            let claim =
                msalService.instance.getActiveAccount()! &&
                    getClaimsFromStorage(
                        `cc.${msalConfig.auth.clientId}.${msalService.instance.getActiveAccount()?.idTokenClaims?.oid
                        }.${resource}`
                    )
                    ? window.atob(
                        getClaimsFromStorage(
                            `cc.${msalConfig.auth.clientId}.${msalService.instance.getActiveAccount()?.idTokenClaims?.oid
                            }.${resource}`
                        )
                    )
                    : undefined; // claims challenge e.g {"access_token":{"xms_cc":{"values":["cp1"]}}}
            return {
                ...originalAuthRequest,
                claims: claim,
            };
        },
    };
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MsalGuardConfigurationFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: loginRequest
    };
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        CustomerlistingComponent,
        CustomerInformationComponent,
        CreditnotePromotionsComponent,
        CreditnotePromotionsCustomersComponent,
        CreditnotePromotionsInfoComponent,
        ParkingTransactionListingComponent,
        ParkingTransactionHistoryListingComponent,
        ColumnSelectorComponent,
        CreditNoteListingComponent,
        GlobalExportComponent,
        DialogServiceDialog,
        AddCreditNoteDialog,
        AddCreditNotePromotionDialog,
        AddCommentDialog,
        CollectivepaymentlistingComponent,
        CollectivePaymentTransactionDialog,
        AddblockcommentdialogComponent,
        WithdrawPromotionDialogComponent,
        BlockinghistoryComponent,
        ExportMarketingReportComponent,
        
    ],
    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        AngularMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatCheckboxModule,
        MatButtonModule,
        MatDatepickerModule,
        MsalModule,
        AngularMaterialModule,
        MatToolbarModule 
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MsalGuardConfigurationFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: "DD.MM.YYYY"
                },
                display: {
                    dateInput: 'DD.MM.YYYY',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY'
                },
            }
        },
        MsalService,
        MsalBroadcastService,
        MsalGuard,
        RoleGuard,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
