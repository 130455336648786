<mat-sidenav class="column-side-nav" #columnsSideNav [(opened)]="columnsOpened" position="end" style="width: 250px">
  <mat-toolbar>
    <mat-mat-toolbar-row class="mat-toolbar-header-container">
      <a (click)="columnsOpened = false">
        <div class="flex-wrapper">
          <mat-icon class="column-icon">view_column</mat-icon>
          <span i18n>Columns</span>
        </div>
      </a>
    </mat-mat-toolbar-row>
  </mat-toolbar>
  <mat-selection-list [(ngModel)]="columnList" (selectionChange)="updateColumList()" class="column-list-container">
    <mat-list-option
      *ngFor="let col of columns"
      [selected]="col.visible"
      [value]="col.fieldName"
      disabled="{{ col.alwaysVisibility }}"
    >
      {{ col.displayText }}
    </mat-list-option>
  </mat-selection-list>
  <mat-toolbar>
    <mat-mat-toolbar-row class="mat-toolbar-footer-container">
      <button mat-raised-button (click)="columnsOpened = false">
        <mat-icon>close</mat-icon><span i18n>Close</span>
      </button>
    </mat-mat-toolbar-row>
  </mat-toolbar>
</mat-sidenav>
