import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AlertService } from '../alert/alert.service';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { addCreditNoteResult } from 'src/app/shared/models/addCreditNoteResult';
import { QueryResult } from 'src/app/shared/models/queryResult';
import { CreditNotePageItem } from 'src/app/shared/models/creditNotePageItem';
import { EnumDropdown } from 'src/app/shared/models/enumDropdown';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable({
  providedIn: 'root'
})
export class creditNoteService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor(private http: HttpClient, private alertService: AlertService, @Inject(LOCALE_ID) public locale: string) {
  }

  public addCreditNote(customerId: string, amount: number, reason: string): Observable<addCreditNoteResult> {
    let baseEndpoint = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/customers/${customerId}/creditnotes`;
    let url = `${baseEndpoint}`;

    var data = { amount, reason };
    return this.http.post<addCreditNoteResult>(url, data, { headers: this.headers })
      .pipe(map(a => {
        return a;
      }), catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    console.error('server error:', error);
    if (error.error instanceof Error) {
      const errMessage = error.error.message;
      return throwError(errMessage);
    }
    return throwError('Server Error.');
  }

  public getAllCreditNote(customerId: string, keyword: string, startDate: string, endDate: string, action: string, pageSize: number, pageNumber: number): Observable<QueryResult<CreditNotePageItem>> {
    let baseEndpoint = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/customers/${customerId}/creditnotes`;
    let queryString: [string, string][] = [
      ["keyword", keyword],
      ["startDate", startDate],
      ["endDate", endDate],
      ["action", action],
      ["pageSize", `${pageSize}`],
      ["pageNumber", `${pageNumber}`],
      ["culture", `${this.locale}`]
    ];

    let url = `${baseEndpoint}?${queryString.map(n => n.join(`=`)).join(`&`)}`;

    return this.http.get<QueryResult<CreditNotePageItem>>(url, { headers: this.headers })
      .pipe(map(a => {
        return a;
      }));
  }

  public getCreditNoteAction(): Observable<QueryResult<EnumDropdown>> {
    let baseEndpoint = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/creditnotes/actionEnum?culture=${this.locale}`;

    let url = `${baseEndpoint}`;
    return this.http.get<QueryResult<EnumDropdown>>(url, { headers: this.headers })
      .pipe(map(a => {
        return a;
      }));
  }
}