<span class="cdk-visually-hidden" #tooltipAddCredit i18n>Add Credit Note</span>
<span class="cdk-visually-hidden" #tooltipCancel i18n>Cancel Transaction</span>
<span class="cdk-visually-hidden" #tooltipWithdraw i18n>Withdraw Transaction</span>

<mat-toolbar>
  <mat-toolbar-row>
    <div class="breadcrumb-container">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/customers']" i18n>Customers</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            <a routerLink="/customers/{{ customerReferenceId }}">{{ parkingTransaction.customerName }}</a>
          </li>
          <li class="breadcrumb-item" i18n>Parking</li>
          <li class="breadcrumb-item">{{ parkingTransaction.parkingTransactionReferenceID }}</li>
        </ol>
      </nav>
    </div>
    <div class="content-button-margin">
      <button mat-raised-button (click)="columnSelector.show()">
        <mat-icon>view_column</mat-icon><span i18n>Columns</span>
      </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<app-columnselector #columnSelector [columns]="defaultColumns"></app-columnselector>

<mat-card>
  <mat-card-content>
    <mat-grid-list cols="3" rowHeight="80px">
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div>
          <mat-form-field appearance="none">
            <mat-label i18n>Reference ID</mat-label>
            <input readonly="true" matInput value="{{ parkingTransaction.parkingTransactionReferenceID }}" />
          </mat-form-field>
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div>
          <mat-form-field appearance="none">
            <mat-label i18n>TCS Member ID</mat-label>
            <input readonly="true" matInput value="{{ parkingTransaction.tcsMemberNumber }}" />
          </mat-form-field>
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div>
          <mat-form-field appearance="none">
            <mat-label i18n>License Plate</mat-label>
            <input readonly="true" matInput value="{{ parkingTransaction.carPlateNumber }}" />
          </mat-form-field>
        </div>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div>
          <mat-form-field appearance="none">
            <mat-label i18n>Vehicle Type</mat-label>
            <input readonly="true" matInput value="{{ parkingTransaction.vehicleType }}" />
          </mat-form-field>
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div>
          <mat-form-field appearance="none">
            <mat-label i18n>Parking Location</mat-label>
            <input readonly="true" matInput value="{{ parkingTransaction.parkingLocationDescription }}" />
          </mat-form-field>
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div>
          <mat-form-field appearance="none">
            <mat-label i18n>Parking Provider</mat-label>
            <input readonly="true" matInput value="{{ parkingTransaction.parkingProviderID }}" />
          </mat-form-field>
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div>
          <mat-form-field appearance="none" class="highlight">
            <mat-label i18n>Total Payable Amount</mat-label>
            <input readonly="true" matInput value="{{ parkingTransaction.payable | currency: '' : '' }} CHF" />
          </mat-form-field>
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div>
          <button
            mat-raised-button
            *ngIf="parkingTransaction.status == 'PAYABLE'"
            (click)="cancelTransaction(parkingTransaction)"
            [matTooltip]="toolTipText(parkingTransaction)"
            matTooltipPosition="above"
            i18n
          >
            Cancel Transaction
          </button>

          <button
            mat-raised-button
            *ngIf="parkingTransaction.status == 'CANCELLED'"
            disabled
            (click)="cancelTransaction(parkingTransaction)"
            [matTooltip]="tooltipWithdraw.innerHTML"
            matTooltipPosition="above"
            i18n
          >
            Transaction Cancelled
          </button>

          <button
            mat-raised-button
            *ngIf="parkingTransaction.status == 'COLLECTED' || parkingTransaction.status == 'PARTIALLYCOLLECTED'"
            (click)="withdrawTransaction(parkingTransaction)"
            [matTooltip]="toolTipText(parkingTransaction)"
            matTooltipPosition="above"
            i18n
          >
            Withdraw Transaction
          </button>

          <button
            mat-raised-button
            *ngIf="parkingTransaction.status == 'WITHDRAWN'"
            disabled
            [matTooltip]="tooltipWithdraw.innerHTML"
            matTooltipPosition="above"
            i18n
          >
            Transaction Withdrawn
          </button>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>

<mat-divider></mat-divider>

<div class="pagedtable-container">
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner></mat-spinner>
  </div>

  <div class="table-container">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row"></td>
      </ng-container>

      <ng-container matColumnDef="Start">
        <th mat-header-cell *matHeaderCellDef><span i18n>Start</span></th>
        <td mat-cell *matCellDef="let element">{{ element.parkingStart }}</td>
      </ng-container>

      <ng-container matColumnDef="End">
        <th mat-header-cell *matHeaderCellDef><span i18n>End</span></th>
        <td mat-cell *matCellDef="let element">{{ element.parkingEnd }}</td>
      </ng-container>

      <ng-container matColumnDef="Duration">
        <th mat-header-cell *matHeaderCellDef><span i18n>Duration</span></th>
        <td mat-cell *matCellDef="let element">{{ element.durationText }}</td>
      </ng-container>

      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef><span i18n>Status</span></th>
        <td mat-cell *matCellDef="let element">{{ element.statusText }}</td>
      </ng-container>

      <ng-container matColumnDef="PaymentMeans">
        <th mat-header-cell *matHeaderCellDef><span i18n>Payment Mean</span></th>
        <td mat-cell *matCellDef="let element">{{ element.meansOfPaymentDisplayText }}</td>
      </ng-container>

      <ng-container matColumnDef="Amount">
        <th mat-header-cell *matHeaderCellDef><span i18n>Amount</span></th>
        <td mat-cell *matCellDef="let element">{{ element.amount | currency: '' : '' }} CHF</td>
      </ng-container>

      <ng-container matColumnDef="CCHolder">
        <th mat-header-cell *matHeaderCellDef><span i18n>CC Holder</span></th>
        <td mat-cell *matCellDef="let element">{{ element.holderName }}</td>
      </ng-container>

      <ng-container matColumnDef="CCNo">
        <th mat-header-cell *matHeaderCellDef><span i18n>CC Number</span></th>
        <td mat-cell *matCellDef="let element">{{ element.cardNumber }}</td>
      </ng-container>

      <ng-container matColumnDef="ExpiryDate">
        <th mat-header-cell *matHeaderCellDef><span i18n>CC Expiry Date</span></th>
        <td mat-cell *matCellDef="let element">{{ element.expiryDateText }}</td>
      </ng-container>

      <ng-container matColumnDef="ModifiedBy">
        <th mat-header-cell *matHeaderCellDef><span i18n>Modified By</span></th>
        <td mat-cell *matCellDef="let element">{{ element.modifiedBy }}</td>
      </ng-container>

      <ng-container matColumnDef="ModifiedDate">
        <th mat-header-cell *matHeaderCellDef><span i18n>Modified Date</span></th>
        <td mat-cell *matCellDef="let element">{{ element.modifiedDateText }}</td>
      </ng-container>

      <ng-container matColumnDef="Cancelled">
        <th mat-header-cell *matHeaderCellDef><span i18n>Cancelled</span></th>
        <td mat-cell *matCellDef="let element">{{ element.isCancelledText }}</td>
      </ng-container>

      <ng-container matColumnDef="Refunded">
        <th mat-header-cell *matHeaderCellDef><span i18n>Refunded</span></th>
        <td mat-cell *matCellDef="let element">{{ element.isRefundedText }}</td>
      </ng-container>

      <ng-container matColumnDef="Comment">
        <th mat-header-cell *matHeaderCellDef><span i18n>Comment</span></th>
        <td mat-cell *matCellDef="let element">{{ element.comment }}</td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="columnSelector == undefined ? [] : columnSelector.displayedColumns; sticky: true"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columnSelector == undefined ? [] : columnSelector.displayedColumns"
        (click)="selection.toggle(row)"
      ></tr>
    </table>
  </div>

  <mat-paginator
    [pageSize]="defaultSettings.table.pageSize"
    [pageSizeOptions]="defaultSettings.table.pageOptions"
    (page)="paginationChaged()"
    showFirstLastButtons
  ></mat-paginator>
</div>
