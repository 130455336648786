<h4 mat-dialog-title>
  <mat-icon class="dialog-icon">list</mat-icon><span class="span-title" i18n>Batch Payment</span>
</h4>
<div *ngIf="!hasCollectivePaymentTransactions"> 
 <span i18n>No collective payment transactions are available.</span>
</div>
<div *ngIf="hasCollectivePaymentTransactions" class="collective-payment-transaction-table-container">
  <app-columnselector #columnSelector [columns]="defaultColumns"></app-columnselector>
  <table mat-table [dataSource]="collectivePaymentTransactionDataSource" class="content-table" id="collective-payment-transaction-dialog-table">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef width="50px">
      </th>
      <td mat-cell *matCellDef="let row">
      </td>
    </ng-container>
    <!-- Position Column -->
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef><span i18n>position</span></th>
      <td mat-cell *matCellDef="let element"> {{element.position}} </td>
    </ng-container>
    <ng-container matColumnDef="ParkingTransactionId">
      <th mat-header-cell *matHeaderCellDef width="10%"><span i18n>Transaction ID</span></th>
      <td mat-cell *matCellDef="let element">{{element.referenceID}}</td>
    </ng-container>
    <ng-container matColumnDef="Amount">
      <th mat-header-cell *matHeaderCellDef width="10%"><span i18n>Amount</span></th>
      <td mat-cell *matCellDef="let element"> {{element.amount | currency:'':''}} </td>
    </ng-container>
    <ng-container matColumnDef="ParkingDate">
      <th mat-header-cell *matHeaderCellDef width="10%"><span i18n>Parking Date</span></th>
      <td mat-cell *matCellDef="let element"> {{element.parkingDateText}} </td>
    </ng-container>
    <ng-container matColumnDef="ParkingLocationDescription">
      <th mat-header-cell *matHeaderCellDef width="10%"><span i18n>Parking Location</span></th>
      <td mat-cell *matCellDef="let element"> {{element.parkingLocationDescription}} </td>
    </ng-container>
    <ng-container matColumnDef="VehicleNumber">
      <th mat-header-cell *matHeaderCellDef><span i18n>License Plate</span></th>
      <td mat-cell *matCellDef="let element"> {{element.numberPlate}} </td>
    </ng-container>
  
    <tr mat-header-row
      *matHeaderRowDef="(columnSelector == undefined ? [] : columnSelector.displayedColumns); sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: (columnSelector == undefined ? [] : columnSelector.displayedColumns);">
  </table>
</div>
