<div mat-dialog-title class="dialog-title-container">
  <mat-icon class="title-icon">credit_card</mat-icon><span i18n>Add Credit Note</span>
</div>
<form [formGroup]="addCreditNoteForm" *ngIf="pageLoaded">
  <div mat-dialog-content>
    <mat-form-field class="form-field">
      <mat-label><span i18n>Amount (CHF)</span></mat-label>
      <input matInput formControlName="amount" required />
      <mat-error *ngIf="f.amount.invalid">
        <span *ngIf="f.amount.hasError('required')" i18n>You must enter a value</span>
        <span *ngIf="f.amount.hasError('pattern')" i18n>You must enter a valid amount e.g. 1 or 1.00</span>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="form-field">
      <mat-label><span i18n>Reason</span></mat-label>
      <textarea matInput formControlName="reason" required [maxlength]="reasonMaxLength"></textarea>
      <mat-error *ngIf="f.reason.invalid">
        <span *ngIf="f.reason.hasError('required')" i18n>You must enter a value</span>
        <span *ngIf="f.reason.hasError('maxlength')" i18n>Max length for reason is 250 characters</span>
      </mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="dialog-actions-container">
    <button
      mat-button
      color="primary"
      [disabled]="f.amount.invalid || f.reason.invalid"
      (click)="onConfirmClick()"
      i18n
    >
      Confirm
    </button>
    <button mat-button color="warn" (click)="onCancelClick()" i18n>Cancel</button>
  </div>
</form>
